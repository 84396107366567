@import "prismjs/themes/prism.css";

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url("../../../shared/toolkit/src/fonts/roboto-latin-100-normal.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("../../../shared/toolkit/src/fonts/roboto-latin-300-normal.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../../../shared/toolkit/src/fonts/roboto-latin-400-normal.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("../../../shared/toolkit/src/fonts/roboto-latin-500-normal.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("../../../shared/toolkit/src/fonts/roboto-latin-700-normal.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url("../../../shared/toolkit/src/fonts/roboto-latin-900-normal.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: Roboto, ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, input:where([type="button"]), input:where([type="reset"]), input:where([type="submit"]) {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden]:where(:not([hidden="until-found"])) {
  display: none;
}

input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.container {
  width: 100%;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 810px) {
  .container {
    max-width: 810px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1125px) {
  .container {
    max-width: 1125px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1500px) {
  .container {
    max-width: 1500px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

@media (min-width: 1900px) {
  .container {
    max-width: 1900px;
  }
}

.pointer-events-none {
  pointer-events: none;
}

.pointer-events-auto {
  pointer-events: auto;
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

.collapse {
  visibility: collapse;
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: sticky;
}

.inset-0 {
  inset: 0;
}

.inset-y-0 {
  top: 0;
  bottom: 0;
}

.-bottom-2 {
  bottom: -.5rem;
}

.-bottom-3 {
  bottom: -.75rem;
}

.-bottom-4 {
  bottom: -1rem;
}

.-bottom-5 {
  bottom: -1.25rem;
}

.-bottom-\[12px\] {
  bottom: -12px;
}

.-left-0\.5 {
  left: -.125rem;
}

.-left-1 {
  left: -.25rem;
}

.-left-6 {
  left: -1.5rem;
}

.-left-\[15px\] {
  left: -15px;
}

.-left-\[5px\] {
  left: -5px;
}

.-left-px {
  left: -1px;
}

.-right-1 {
  right: -.25rem;
}

.-right-4 {
  right: -1rem;
}

.-right-8 {
  right: -2rem;
}

.-top-0\.5 {
  top: -.125rem;
}

.-top-1 {
  top: -.25rem;
}

.-top-12 {
  top: -3rem;
}

.-top-2 {
  top: -.5rem;
}

.-top-3 {
  top: -.75rem;
}

.-top-4 {
  top: -1rem;
}

.-top-6 {
  top: -1.5rem;
}

.-top-8 {
  top: -2rem;
}

.-top-\[0\.4rem\] {
  top: -.4rem;
}

.bottom-0 {
  bottom: 0;
}

.bottom-0\.5 {
  bottom: .125rem;
}

.bottom-10 {
  bottom: 2.5rem;
}

.bottom-16 {
  bottom: 4rem;
}

.bottom-2 {
  bottom: .5rem;
}

.bottom-20 {
  bottom: 5rem;
}

.bottom-24 {
  bottom: 6rem;
}

.bottom-5 {
  bottom: 1.25rem;
}

.bottom-6 {
  bottom: 1.5rem;
}

.bottom-\[-10px\] {
  bottom: -10px;
}

.bottom-auto {
  bottom: auto;
}

.end-1 {
  inset-inline-end: .25rem;
}

.end-2 {
  inset-inline-end: .5rem;
}

.left-0 {
  left: 0;
}

.left-0\.5 {
  left: .125rem;
}

.left-1 {
  left: .25rem;
}

.left-1\/2 {
  left: 50%;
}

.left-10 {
  left: 2.5rem;
}

.left-2 {
  left: .5rem;
}

.left-20 {
  left: 5rem;
}

.left-4 {
  left: 1rem;
}

.left-5 {
  left: 1.25rem;
}

.left-7 {
  left: 1.75rem;
}

.left-\[160px\] {
  left: 160px;
}

.left-\[23px\] {
  left: 23px;
}

.left-\[26px\] {
  left: 26px;
}

.left-auto {
  left: auto;
}

.left-full {
  left: 100%;
}

.right-0 {
  right: 0;
}

.right-1 {
  right: .25rem;
}

.right-1\.5 {
  right: .375rem;
}

.right-10 {
  right: 2.5rem;
}

.right-12 {
  right: 3rem;
}

.right-2 {
  right: .5rem;
}

.right-2\.5 {
  right: .625rem;
}

.right-20 {
  right: 5rem;
}

.right-24 {
  right: 6rem;
}

.right-3 {
  right: .75rem;
}

.right-3\.5 {
  right: .875rem;
}

.right-4 {
  right: 1rem;
}

.right-5 {
  right: 1.25rem;
}

.right-6 {
  right: 1.5rem;
}

.right-\[50px\] {
  right: 50px;
}

.right-\[80px\] {
  right: 80px;
}

.right-full {
  right: 100%;
}

.top-0 {
  top: 0;
}

.top-0\.5 {
  top: .125rem;
}

.top-1\.5 {
  top: .375rem;
}

.top-1\/2 {
  top: 50%;
}

.top-2 {
  top: .5rem;
}

.top-2\.5 {
  top: .625rem;
}

.top-20 {
  top: 5rem;
}

.top-28 {
  top: 7rem;
}

.top-3\.5 {
  top: .875rem;
}

.top-36 {
  top: 9rem;
}

.top-4 {
  top: 1rem;
}

.top-5 {
  top: 1.25rem;
}

.top-52 {
  top: 13rem;
}

.top-6 {
  top: 1.5rem;
}

.top-8 {
  top: 2rem;
}

.top-\[-10px\] {
  top: -10px;
}

.top-\[25px\] {
  top: 25px;
}

.top-\[45px\] {
  top: 45px;
}

.top-\[50\%\] {
  top: 50%;
}

.top-\[50px\] {
  top: 50px;
}

.top-\[65\%\] {
  top: 65%;
}

.top-auto {
  top: auto;
}

.top-full {
  top: 100%;
}

.-z-10 {
  z-index: -10;
}

.z-0 {
  z-index: 0;
}

.z-10 {
  z-index: 10;
}

.z-20 {
  z-index: 20;
}

.z-30 {
  z-index: 30;
}

.z-40 {
  z-index: 40;
}

.z-50 {
  z-index: 50;
}

.z-\[-1\] {
  z-index: -1;
}

.z-\[100\] {
  z-index: 100;
}

.z-\[1\] {
  z-index: 1;
}

.z-\[5\] {
  z-index: 5;
}

.z-\[9\] {
  z-index: 9;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.col-span-1 {
  grid-column: span 1 / span 1;
}

.col-span-11 {
  grid-column: span 11 / span 11;
}

.col-span-2 {
  grid-column: span 2 / span 2;
}

.col-span-3 {
  grid-column: span 3 / span 3;
}

.col-span-4 {
  grid-column: span 4 / span 4;
}

.col-span-7 {
  grid-column: span 7 / span 7;
}

.col-span-full {
  grid-column: 1 / -1;
}

.row-span-1 {
  grid-row: span 1 / span 1;
}

.row-span-2 {
  grid-row: span 2 / span 2;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.m-0 {
  margin: 0;
}

.m-1 {
  margin: .25rem;
}

.m-2 {
  margin: .5rem;
}

.m-4 {
  margin: 1rem;
}

.m-5 {
  margin: 1.25rem;
}

.m-6 {
  margin: 1.5rem;
}

.m-8 {
  margin: 2rem;
}

.m-\[2px\] {
  margin: 2px;
}

.m-\[3px\] {
  margin: 3px;
}

.m-auto {
  margin: auto;
}

.-mx-1 {
  margin-left: -.25rem;
  margin-right: -.25rem;
}

.-mx-2 {
  margin-left: -.5rem;
  margin-right: -.5rem;
}

.-mx-6 {
  margin-left: -1.5rem;
  margin-right: -1.5rem;
}

.mx-0 {
  margin-left: 0;
  margin-right: 0;
}

.mx-1 {
  margin-left: .25rem;
  margin-right: .25rem;
}

.mx-2 {
  margin-left: .5rem;
  margin-right: .5rem;
}

.mx-2\.5 {
  margin-left: .625rem;
  margin-right: .625rem;
}

.mx-3 {
  margin-left: .75rem;
  margin-right: .75rem;
}

.mx-3\.5 {
  margin-left: .875rem;
  margin-right: .875rem;
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.mx-5 {
  margin-left: 1.25rem;
  margin-right: 1.25rem;
}

.mx-6 {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.mx-8 {
  margin-left: 2rem;
  margin-right: 2rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.my-1 {
  margin-top: .25rem;
  margin-bottom: .25rem;
}

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.my-2\.5 {
  margin-top: .625rem;
  margin-bottom: .625rem;
}

.my-3 {
  margin-top: .75rem;
  margin-bottom: .75rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-5 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.-mb-1 {
  margin-bottom: -.25rem;
}

.-mb-10 {
  margin-bottom: -2.5rem;
}

.-mb-2 {
  margin-bottom: -.5rem;
}

.-mb-3 {
  margin-bottom: -.75rem;
}

.-mb-4 {
  margin-bottom: -1rem;
}

.-mb-8 {
  margin-bottom: -2rem;
}

.-mb-\[113px\] {
  margin-bottom: -113px;
}

.-mb-\[18px\] {
  margin-bottom: -18px;
}

.-mb-\[26px\] {
  margin-bottom: -26px;
}

.-mb-\[40px\] {
  margin-bottom: -40px;
}

.-mb-px {
  margin-bottom: -1px;
}

.-ml-1 {
  margin-left: -.25rem;
}

.-ml-2 {
  margin-left: -.5rem;
}

.-ml-3 {
  margin-left: -.75rem;
}

.-ml-4 {
  margin-left: -1rem;
}

.-ml-6 {
  margin-left: -1.5rem;
}

.-ml-7 {
  margin-left: -1.75rem;
}

.-ml-\[calc\(\(0\.5625rem\/2\)-0\.25rem\)\] {
  margin-left: -.03125rem;
}

.-ml-\[calc\(0\.5625rem_\/_2\)\] {
  margin-left: -.28125rem;
}

.-mr-1 {
  margin-right: -.25rem;
}

.-mr-1\.5 {
  margin-right: -.375rem;
}

.-mr-3 {
  margin-right: -.75rem;
}

.-mr-4 {
  margin-right: -1rem;
}

.-mr-48 {
  margin-right: -12rem;
}

.-mr-6 {
  margin-right: -1.5rem;
}

.-mr-8 {
  margin-right: -2rem;
}

.-mt-0\.5 {
  margin-top: -.125rem;
}

.-mt-1 {
  margin-top: -.25rem;
}

.-mt-10 {
  margin-top: -2.5rem;
}

.-mt-12 {
  margin-top: -3rem;
}

.-mt-16 {
  margin-top: -4rem;
}

.-mt-2 {
  margin-top: -.5rem;
}

.-mt-2\.5 {
  margin-top: -.625rem;
}

.-mt-3 {
  margin-top: -.75rem;
}

.-mt-4 {
  margin-top: -1rem;
}

.-mt-5 {
  margin-top: -1.25rem;
}

.-mt-6 {
  margin-top: -1.5rem;
}

.-mt-7 {
  margin-top: -1.75rem;
}

.-mt-8 {
  margin-top: -2rem;
}

.-mt-9 {
  margin-top: -2.25rem;
}

.-mt-\[0\.15rem\] {
  margin-top: -.15rem;
}

.-mt-\[13px\] {
  margin-top: -13px;
}

.-mt-\[18px\] {
  margin-top: -18px;
}

.-mt-\[22px\] {
  margin-top: -22px;
}

.-mt-\[30px\] {
  margin-top: -30px;
}

.-mt-\[7px\] {
  margin-top: -7px;
}

.-mt-px {
  margin-top: -1px;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-0\.5 {
  margin-bottom: .125rem;
}

.mb-1 {
  margin-bottom: .25rem;
}

.mb-1\.5 {
  margin-bottom: .375rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.mb-16 {
  margin-bottom: 4rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-20 {
  margin-bottom: 5rem;
}

.mb-28 {
  margin-bottom: 7rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mb-7 {
  margin-bottom: 1.75rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.mb-9 {
  margin-bottom: 2.25rem;
}

.mb-\[1\.6rem\] {
  margin-bottom: 1.6rem;
}

.mb-\[120px\] {
  margin-bottom: 120px;
}

.mb-auto {
  margin-bottom: auto;
}

.me-4 {
  margin-inline-end: 1rem;
}

.ml-0 {
  margin-left: 0;
}

.ml-0\.5 {
  margin-left: .125rem;
}

.ml-1 {
  margin-left: .25rem;
}

.ml-1\.5 {
  margin-left: .375rem;
}

.ml-12 {
  margin-left: 3rem;
}

.ml-16 {
  margin-left: 4rem;
}

.ml-2 {
  margin-left: .5rem;
}

.ml-2\.5 {
  margin-left: .625rem;
}

.ml-20 {
  margin-left: 5rem;
}

.ml-24 {
  margin-left: 6rem;
}

.ml-28 {
  margin-left: 7rem;
}

.ml-3 {
  margin-left: .75rem;
}

.ml-3\.5 {
  margin-left: .875rem;
}

.ml-4 {
  margin-left: 1rem;
}

.ml-40 {
  margin-left: 10rem;
}

.ml-5 {
  margin-left: 1.25rem;
}

.ml-6 {
  margin-left: 1.5rem;
}

.ml-7 {
  margin-left: 1.75rem;
}

.ml-8 {
  margin-left: 2rem;
}

.ml-\[12px\] {
  margin-left: 12px;
}

.ml-\[23rem\] {
  margin-left: 23rem;
}

.ml-\[3px\] {
  margin-left: 3px;
}

.ml-\[4px\] {
  margin-left: 4px;
}

.ml-auto {
  margin-left: auto;
}

.mr-0 {
  margin-right: 0;
}

.mr-1 {
  margin-right: .25rem;
}

.mr-10 {
  margin-right: 2.5rem;
}

.mr-16 {
  margin-right: 4rem;
}

.mr-2 {
  margin-right: .5rem;
}

.mr-3 {
  margin-right: .75rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mr-5 {
  margin-right: 1.25rem;
}

.mr-6 {
  margin-right: 1.5rem;
}

.mr-8 {
  margin-right: 2rem;
}

.mr-\[20\%\] {
  margin-right: 20%;
}

.ms-4 {
  margin-inline-start: 1rem;
}

.mt-0 {
  margin-top: 0;
}

.mt-0\.5 {
  margin-top: .125rem;
}

.mt-1 {
  margin-top: .25rem;
}

.mt-1\.5 {
  margin-top: .375rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mt-12 {
  margin-top: 3rem;
}

.mt-16 {
  margin-top: 4rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-2\.5 {
  margin-top: .625rem;
}

.mt-20 {
  margin-top: 5rem;
}

.mt-24 {
  margin-top: 6rem;
}

.mt-3 {
  margin-top: .75rem;
}

.mt-3\.5 {
  margin-top: .875rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mt-7 {
  margin-top: 1.75rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mt-\[30px\] {
  margin-top: 30px;
}

.mt-\[3px\] {
  margin-top: 3px;
}

.mt-\[4px\] {
  margin-top: 4px;
}

.mt-\[5px\] {
  margin-top: 5px;
}

.mt-\[60px\] {
  margin-top: 60px;
}

.mt-\[70px\] {
  margin-top: 70px;
}

.mt-\[8px\] {
  margin-top: 8px;
}

.mt-auto {
  margin-top: auto;
}

.mt-px {
  margin-top: 1px;
}

.box-content {
  box-sizing: content-box;
}

.line-clamp-2 {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.table {
  display: table;
}

.flow-root {
  display: flow-root;
}

.\!grid {
  display: grid !important;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.aspect-square {
  aspect-ratio: 1;
}

.aspect-video {
  aspect-ratio: 16 / 9;
}

.h-0 {
  height: 0;
}

.h-0\.5 {
  height: .125rem;
}

.h-1 {
  height: .25rem;
}

.h-1\.5 {
  height: .375rem;
}

.h-1\/2 {
  height: 50%;
}

.h-1\/3 {
  height: 33.3333%;
}

.h-10 {
  height: 2.5rem;
}

.h-11 {
  height: 2.75rem;
}

.h-12 {
  height: 3rem;
}

.h-128 {
  height: 30rem;
}

.h-14 {
  height: 3.5rem;
}

.h-16 {
  height: 4rem;
}

.h-2 {
  height: .5rem;
}

.h-2\.5 {
  height: .625rem;
}

.h-2\/3 {
  height: 66.6667%;
}

.h-20 {
  height: 5rem;
}

.h-22 {
  height: 5.5rem;
}

.h-24 {
  height: 6rem;
}

.h-28 {
  height: 7rem;
}

.h-3 {
  height: .75rem;
}

.h-32 {
  height: 8rem;
}

.h-36 {
  height: 9rem;
}

.h-4 {
  height: 1rem;
}

.h-4\/5 {
  height: 80%;
}

.h-40 {
  height: 10rem;
}

.h-5 {
  height: 1.25rem;
}

.h-52 {
  height: 13rem;
}

.h-6 {
  height: 1.5rem;
}

.h-60 {
  height: 15rem;
}

.h-64 {
  height: 16rem;
}

.h-7 {
  height: 1.75rem;
}

.h-8 {
  height: 2rem;
}

.h-80 {
  height: 20rem;
}

.h-9 {
  height: 2.25rem;
}

.h-96 {
  height: 24rem;
}

.h-\[0\.5625rem\] {
  height: .5625rem;
}

.h-\[100px\] {
  height: 100px;
}

.h-\[106px\] {
  height: 106px;
}

.h-\[10px\] {
  height: 10px;
}

.h-\[113px\] {
  height: 113px;
}

.h-\[14px\] {
  height: 14px;
}

.h-\[15000px\] {
  height: 15000px;
}

.h-\[160px\] {
  height: 160px;
}

.h-\[18px\] {
  height: 18px;
}

.h-\[1px\] {
  height: 1px;
}

.h-\[200px\] {
  height: 200px;
}

.h-\[205px\] {
  height: 205px;
}

.h-\[209px\] {
  height: 209px;
}

.h-\[22px\] {
  height: 22px;
}

.h-\[250px\] {
  height: 250px;
}

.h-\[29px\] {
  height: 29px;
}

.h-\[2px\] {
  height: 2px;
}

.h-\[300px\] {
  height: 300px;
}

.h-\[310px\] {
  height: 310px;
}

.h-\[342px\] {
  height: 342px;
}

.h-\[38px\] {
  height: 38px;
}

.h-\[420px\] {
  height: 420px;
}

.h-\[42px\] {
  height: 42px;
}

.h-\[450px\] {
  height: 450px;
}

.h-\[45px\] {
  height: 45px;
}

.h-\[480px\] {
  height: 480px;
}

.h-\[48px\] {
  height: 48px;
}

.h-\[495px\] {
  height: 495px;
}

.h-\[4px\] {
  height: 4px;
}

.h-\[500px\] {
  height: 500px;
}

.h-\[50px\] {
  height: 50px;
}

.h-\[520px\] {
  height: 520px;
}

.h-\[560px\] {
  height: 560px;
}

.h-\[58px\] {
  height: 58px;
}

.h-\[60px\] {
  height: 60px;
}

.h-\[650px\] {
  height: 650px;
}

.h-\[67vh\] {
  height: 67vh;
}

.h-\[68px\] {
  height: 68px;
}

.h-\[70px\] {
  height: 70px;
}

.h-\[720px\] {
  height: 720px;
}

.h-\[87px\] {
  height: 87px;
}

.h-\[88px\] {
  height: 88px;
}

.h-\[90px\] {
  height: 90px;
}

.h-\[calc\(100\%\+0\.8rem\)\] {
  height: calc(100% + .8rem);
}

.h-\[calc\(100\%-1\.25rem\)\] {
  height: calc(100% - 1.25rem);
}

.h-auto {
  height: auto;
}

.h-fit {
  height: fit-content;
}

.h-full {
  height: 100%;
}

.h-min {
  height: min-content;
}

.h-screen {
  height: 100vh;
}

.max-h-72 {
  max-height: 18rem;
}

.max-h-80 {
  max-height: 20rem;
}

.max-h-96 {
  max-height: 24rem;
}

.max-h-\[150px\] {
  max-height: 150px;
}

.max-h-\[200px\] {
  max-height: 200px;
}

.max-h-\[240px\] {
  max-height: 240px;
}

.max-h-\[250px\] {
  max-height: 250px;
}

.max-h-\[30\%\] {
  max-height: 30%;
}

.max-h-\[300px\] {
  max-height: 300px;
}

.max-h-\[400px\] {
  max-height: 400px;
}

.max-h-\[584px\] {
  max-height: 584px;
}

.max-h-\[65vh\] {
  max-height: 65vh;
}

.max-h-\[80\%\] {
  max-height: 80%;
}

.max-h-\[80vh\] {
  max-height: 80vh;
}

.max-h-\[90\%\] {
  max-height: 90%;
}

.max-h-\[90vh\] {
  max-height: 90vh;
}

.max-h-\[calc\(\(100\%_\/_3\)_\*_2\)\] {
  max-height: 66.6667%;
}

.max-h-\[calc\(100\%_-_148px\)\] {
  max-height: calc(100% - 148px);
}

.max-h-\[calc\(100\%_-_65px\)\] {
  max-height: calc(100% - 65px);
}

.max-h-screen {
  max-height: 100vh;
}

.min-h-0 {
  min-height: 0;
}

.min-h-16 {
  min-height: 4rem;
}

.min-h-20 {
  min-height: 5rem;
}

.min-h-40 {
  min-height: 10rem;
}

.min-h-5 {
  min-height: 1.25rem;
}

.min-h-\[2rem\] {
  min-height: 2rem;
}

.min-h-\[3\.5rem\] {
  min-height: 3.5rem;
}

.min-h-\[300px\] {
  min-height: 300px;
}

.min-h-\[425px\] {
  min-height: 425px;
}

.min-h-\[52px\] {
  min-height: 52px;
}

.min-h-\[568px\] {
  min-height: 568px;
}

.min-h-\[56px\] {
  min-height: 56px;
}

.min-h-\[60px\] {
  min-height: 60px;
}

.min-h-\[64px\] {
  min-height: 64px;
}

.min-h-\[65px\] {
  min-height: 65px;
}

.min-h-\[750px\] {
  min-height: 750px;
}

.min-h-\[92px\] {
  min-height: 92px;
}

.min-h-full {
  min-height: 100%;
}

.min-h-screen {
  min-height: 100vh;
}

.\!w-0 {
  width: 0 !important;
}

.w-0 {
  width: 0;
}

.w-1 {
  width: .25rem;
}

.w-1\.5 {
  width: .375rem;
}

.w-1\/12 {
  width: 8.33333%;
}

.w-1\/2 {
  width: 50%;
}

.w-1\/3 {
  width: 33.3333%;
}

.w-1\/4 {
  width: 25%;
}

.w-1\/5 {
  width: 20%;
}

.w-1\/6 {
  width: 16.6667%;
}

.w-10 {
  width: 2.5rem;
}

.w-10\/12 {
  width: 83.3333%;
}

.w-11 {
  width: 2.75rem;
}

.w-11\/12 {
  width: 91.6667%;
}

.w-12 {
  width: 3rem;
}

.w-14 {
  width: 3.5rem;
}

.w-16 {
  width: 4rem;
}

.w-2 {
  width: .5rem;
}

.w-2\.5 {
  width: .625rem;
}

.w-2\/12 {
  width: 16.6667%;
}

.w-2\/3 {
  width: 66.6667%;
}

.w-2\/4 {
  width: 50%;
}

.w-2\/5 {
  width: 40%;
}

.w-2\/6 {
  width: 33.3333%;
}

.w-20 {
  width: 5rem;
}

.w-24 {
  width: 6rem;
}

.w-28 {
  width: 7rem;
}

.w-3 {
  width: .75rem;
}

.w-3\/12 {
  width: 25%;
}

.w-3\/4 {
  width: 75%;
}

.w-3\/5 {
  width: 60%;
}

.w-3\/6 {
  width: 50%;
}

.w-32 {
  width: 8rem;
}

.w-36 {
  width: 9rem;
}

.w-4 {
  width: 1rem;
}

.w-4\/12 {
  width: 33.3333%;
}

.w-4\/6 {
  width: 66.6667%;
}

.w-40 {
  width: 10rem;
}

.w-44 {
  width: 11rem;
}

.w-48 {
  width: 12rem;
}

.w-5 {
  width: 1.25rem;
}

.w-5\/12 {
  width: 41.6667%;
}

.w-5\/6 {
  width: 83.3333%;
}

.w-52 {
  width: 13rem;
}

.w-56 {
  width: 14rem;
}

.w-6 {
  width: 1.5rem;
}

.w-6\/12 {
  width: 50%;
}

.w-60 {
  width: 15rem;
}

.w-7 {
  width: 1.75rem;
}

.w-7\/12 {
  width: 58.3333%;
}

.w-72 {
  width: 18rem;
}

.w-8 {
  width: 2rem;
}

.w-8\/12 {
  width: 66.6667%;
}

.w-80 {
  width: 20rem;
}

.w-9 {
  width: 2.25rem;
}

.w-9\/12 {
  width: 75%;
}

.w-96 {
  width: 24rem;
}

.w-\[0\.5625rem\] {
  width: .5625rem;
}

.w-\[10\%\] {
  width: 10%;
}

.w-\[100px\] {
  width: 100px;
}

.w-\[1024px\] {
  width: 1024px;
}

.w-\[104px\] {
  width: 104px;
}

.w-\[1170px\] {
  width: 1170px;
}

.w-\[12\%\] {
  width: 12%;
}

.w-\[1232px\] {
  width: 1232px;
}

.w-\[1280px\] {
  width: 1280px;
}

.w-\[130px\] {
  width: 130px;
}

.w-\[14\%\] {
  width: 14%;
}

.w-\[150px\] {
  width: 150px;
}

.w-\[155px\] {
  width: 155px;
}

.w-\[16\%\] {
  width: 16%;
}

.w-\[160px\] {
  width: 160px;
}

.w-\[1755px\] {
  width: 1755px;
}

.w-\[184px\] {
  width: 184px;
}

.w-\[18px\] {
  width: 18px;
}

.w-\[200px\] {
  width: 200px;
}

.w-\[210px\] {
  width: 210px;
}

.w-\[21px\] {
  width: 21px;
}

.w-\[22px\] {
  width: 22px;
}

.w-\[241px\] {
  width: 241px;
}

.w-\[245px\] {
  width: 245px;
}

.w-\[25\%\] {
  width: 25%;
}

.w-\[260px\] {
  width: 260px;
}

.w-\[2px\] {
  width: 2px;
}

.w-\[305px\] {
  width: 305px;
}

.w-\[30px\] {
  width: 30px;
}

.w-\[320px\] {
  width: 320px;
}

.w-\[32px\] {
  width: 32px;
}

.w-\[32rem\] {
  width: 32rem;
}

.w-\[342px\] {
  width: 342px;
}

.w-\[360px\] {
  width: 360px;
}

.w-\[38px\] {
  width: 38px;
}

.w-\[420px\] {
  width: 420px;
}

.w-\[422px\] {
  width: 422px;
}

.w-\[44px\] {
  width: 44px;
}

.w-\[469px\] {
  width: 469px;
}

.w-\[50\%\] {
  width: 50%;
}

.w-\[500px\] {
  width: 500px;
}

.w-\[50px\] {
  width: 50px;
}

.w-\[537px\] {
  width: 537px;
}

.w-\[55\%\] {
  width: 55%;
}

.w-\[550px\] {
  width: 550px;
}

.w-\[552px\] {
  width: 552px;
}

.w-\[55px\] {
  width: 55px;
}

.w-\[56px\] {
  width: 56px;
}

.w-\[57px\] {
  width: 57px;
}

.w-\[600px\] {
  width: 600px;
}

.w-\[60px\] {
  width: 60px;
}

.w-\[700px\] {
  width: 700px;
}

.w-\[740px\] {
  width: 740px;
}

.w-\[75px\] {
  width: 75px;
}

.w-\[76px\] {
  width: 76px;
}

.w-\[78px\] {
  width: 78px;
}

.w-\[80px\] {
  width: 80px;
}

.w-\[848px\] {
  width: 848px;
}

.w-\[88px\] {
  width: 88px;
}

.w-\[90px\] {
  width: 90px;
}

.w-\[9px\] {
  width: 9px;
}

.w-\[calc\(\(100\%\/14\*2\)-10px\)\] {
  width: calc(14.2857% - 10px);
}

.w-\[calc\(\(100\%\/14\*2\)-42px\)\] {
  width: calc(14.2857% - 42px);
}

.w-\[calc\(\(100\%\/14\*5\)-10px\)\] {
  width: calc(35.7143% - 10px);
}

.w-\[calc\(\(100\%\/14\*5\.5\)-42px\)\] {
  width: calc(39.2857% - 42px);
}

.w-\[calc\(100\%_-28px\)\] {
  width: calc(100% - 28px);
}

.w-\[calc\(100\%_-_1\.25rem\)\] {
  width: calc(100% - 1.25rem);
}

.w-\[calc\(100\%_-_4rem_-_2rem\)\] {
  width: calc(100% - 6rem);
}

.w-auto {
  width: auto;
}

.w-fit {
  width: fit-content;
}

.w-full {
  width: 100%;
}

.w-max {
  width: max-content;
}

.w-min {
  width: min-content;
}

.w-px {
  width: 1px;
}

.w-screen {
  width: 100vw;
}

.\!min-w-0 {
  min-width: 0 !important;
}

.min-w-0 {
  min-width: 0;
}

.min-w-28 {
  min-width: 7rem;
}

.min-w-4 {
  min-width: 1rem;
}

.min-w-52 {
  min-width: 13rem;
}

.min-w-\[1000px\] {
  min-width: 1000px;
}

.min-w-\[1200px\] {
  min-width: 1200px;
}

.min-w-\[128px\] {
  min-width: 128px;
}

.min-w-\[130px\] {
  min-width: 130px;
}

.min-w-\[1383px\] {
  min-width: 1383px;
}

.min-w-\[1690px\] {
  min-width: 1690px;
}

.min-w-\[200px\] {
  min-width: 200px;
}

.min-w-\[20px\] {
  min-width: 20px;
}

.min-w-\[240px\] {
  min-width: 240px;
}

.min-w-\[28px\] {
  min-width: 28px;
}

.min-w-\[300px\] {
  min-width: 300px;
}

.min-w-\[32px\] {
  min-width: 32px;
}

.min-w-\[33px\] {
  min-width: 33px;
}

.min-w-\[44px\] {
  min-width: 44px;
}

.min-w-\[500px\] {
  min-width: 500px;
}

.min-w-\[80px\] {
  min-width: 80px;
}

.min-w-\[88px\] {
  min-width: 88px;
}

.min-w-fit {
  min-width: fit-content;
}

.min-w-full {
  min-width: 100%;
}

.max-w-4xl {
  max-width: 56rem;
}

.max-w-7xl {
  max-width: 80rem;
}

.max-w-\[100px\] {
  max-width: 100px;
}

.max-w-\[100vw\] {
  max-width: 100vw;
}

.max-w-\[160px\] {
  max-width: 160px;
}

.max-w-\[1800px\] {
  max-width: 1800px;
}

.max-w-\[2000px\] {
  max-width: 2000px;
}

.max-w-\[200px\] {
  max-width: 200px;
}

.max-w-\[213px\] {
  max-width: 213px;
}

.max-w-\[300px\] {
  max-width: 300px;
}

.max-w-\[308px\] {
  max-width: 308px;
}

.max-w-\[340px\] {
  max-width: 340px;
}

.max-w-\[365px\] {
  max-width: 365px;
}

.max-w-\[369px\] {
  max-width: 369px;
}

.max-w-\[404px\] {
  max-width: 404px;
}

.max-w-\[428px\] {
  max-width: 428px;
}

.max-w-\[56px\] {
  max-width: 56px;
}

.max-w-\[600px\] {
  max-width: 600px;
}

.max-w-\[82px\] {
  max-width: 82px;
}

.max-w-\[85px\] {
  max-width: 85px;
}

.max-w-\[950px\] {
  max-width: 950px;
}

.max-w-\[calc\(100\%-100px\)\] {
  max-width: calc(100% - 100px);
}

.max-w-\[calc\(100\%-35px\)\] {
  max-width: calc(100% - 35px);
}

.max-w-\[calc\(100\%-365px\)\] {
  max-width: calc(100% - 365px);
}

.max-w-\[calc\(100\%-950px\)\] {
  max-width: calc(100% - 950px);
}

.max-w-full {
  max-width: 100%;
}

.max-w-xl {
  max-width: 36rem;
}

.\!flex-none {
  flex: none !important;
}

.flex-1 {
  flex: 1;
}

.flex-auto {
  flex: auto;
}

.flex-none {
  flex: none;
}

.flex-shrink {
  flex-shrink: 1;
}

.flex-shrink-0, .shrink-0 {
  flex-shrink: 0;
}

.flex-grow {
  flex-grow: 1;
}

.flex-grow-0 {
  flex-grow: 0;
}

.grow {
  flex-grow: 1;
}

.basis-0 {
  flex-basis: 0;
}

.table-fixed {
  table-layout: fixed;
}

.border-separate {
  border-collapse: separate;
}

.border-spacing-0 {
  --tw-border-spacing-x: 0px;
  --tw-border-spacing-y: 0px;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.origin-0 {
  transform-origin: 0%;
}

.origin-top-right {
  transform-origin: 100% 0;
}

.-translate-x-1\/2 {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-x-8 {
  --tw-translate-x: -2rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-x-96 {
  --tw-translate-x: -24rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-1 {
  --tw-translate-y: -.25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-1\.5 {
  --tw-translate-y: -.375rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-1\/2 {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-10 {
  --tw-translate-y: -2.5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-24 {
  --tw-translate-y: -6rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-3 {
  --tw-translate-y: -.75rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-full {
  --tw-translate-y: -100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-0 {
  --tw-translate-x: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-1\/2 {
  --tw-translate-x: 50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-80 {
  --tw-translate-x: 20rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-96 {
  --tw-translate-x: 24rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-\[25px\] {
  --tw-translate-x: 25px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-full {
  --tw-translate-x: 100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-0 {
  --tw-translate-y: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-1 {
  --tw-translate-y: .25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-4 {
  --tw-translate-y: 1rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-rotate-12 {
  --tw-rotate: -12deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-rotate-180 {
  --tw-rotate: -180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-rotate-45 {
  --tw-rotate: -45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-rotate-90 {
  --tw-rotate: -90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-0 {
  --tw-rotate: 0deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-180 {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-45 {
  --tw-rotate: 45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-90 {
  --tw-rotate: 90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-\[135deg\] {
  --tw-rotate: 135deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-100 {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-95 {
  --tw-scale-x: .95;
  --tw-scale-y: .95;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-x-\[-1\] {
  --tw-scale-x: -1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.animate-spin {
  animation: 1s linear infinite spin;
}

.\!cursor-default {
  cursor: default !important;
}

.cursor-default {
  cursor: default;
}

.cursor-grab {
  cursor: grab;
}

.cursor-grabbing {
  cursor: grabbing;
}

.cursor-move {
  cursor: move;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-text {
  cursor: text;
}

.select-none {
  -webkit-user-select: none;
  user-select: none;
}

.resize {
  resize: both;
}

.list-disc {
  list-style-type: disc;
}

.appearance-none {
  appearance: none;
}

.columns-2 {
  columns: 2;
}

.break-inside-avoid {
  break-inside: avoid;
}

.break-inside-avoid-column {
  break-inside: avoid-column;
}

.grid-flow-col {
  grid-auto-flow: column;
}

.grid-flow-dense {
  grid-auto-flow: dense;
}

.auto-rows-fr {
  grid-auto-rows: minmax(0, 1fr);
}

.auto-rows-min {
  grid-auto-rows: min-content;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-cols-10 {
  grid-template-columns: repeat(10, minmax(0, 1fr));
}

.grid-cols-12 {
  grid-template-columns: repeat(12, minmax(0, 1fr));
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.grid-cols-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}

.grid-cols-8 {
  grid-template-columns: repeat(8, minmax(0, 1fr));
}

.grid-cols-\[min-content_1fr\] {
  grid-template-columns: min-content 1fr;
}

.grid-rows-2 {
  grid-template-rows: repeat(2, minmax(0, 1fr));
}

.grid-rows-\[0\.1fr_1fr\] {
  grid-template-rows: .1fr 1fr;
}

.grid-rows-\[1fr_5fr_1fr\] {
  grid-template-rows: 1fr 5fr 1fr;
}

.grid-rows-\[fit-content\(2fr\)_fit-content\(2fr\)\] {
  grid-template-rows: fit-content(2fr) fit-content(2fr);
}

.flex-row {
  flex-direction: row;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.place-content-center {
  place-content: center;
}

.place-content-end {
  place-content: end;
}

.content-center {
  align-content: center;
}

.content-end {
  align-content: flex-end;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.items-stretch {
  align-items: stretch;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-evenly {
  justify-content: space-evenly;
}

.justify-items-center {
  justify-items: center;
}

.gap-0 {
  gap: 0;
}

.gap-0\.5 {
  gap: .125rem;
}

.gap-1 {
  gap: .25rem;
}

.gap-1\.5 {
  gap: .375rem;
}

.gap-10 {
  gap: 2.5rem;
}

.gap-14 {
  gap: 3.5rem;
}

.gap-2 {
  gap: .5rem;
}

.gap-2\.5 {
  gap: .625rem;
}

.gap-24 {
  gap: 6rem;
}

.gap-3 {
  gap: .75rem;
}

.gap-3\.5 {
  gap: .875rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-44 {
  gap: 11rem;
}

.gap-5 {
  gap: 1.25rem;
}

.gap-6 {
  gap: 1.5rem;
}

.gap-7 {
  gap: 1.75rem;
}

.gap-8 {
  gap: 2rem;
}

.gap-9 {
  gap: 2.25rem;
}

.gap-\[25px\] {
  gap: 25px;
}

.gap-\[30px\] {
  gap: 30px;
}

.gap-x-2 {
  column-gap: .5rem;
}

.gap-x-3 {
  column-gap: .75rem;
}

.gap-x-4 {
  column-gap: 1rem;
}

.gap-x-7 {
  column-gap: 1.75rem;
}

.gap-y-0\.5 {
  row-gap: .125rem;
}

.gap-y-1 {
  row-gap: .25rem;
}

.gap-y-3 {
  row-gap: .75rem;
}

.gap-y-3\.5 {
  row-gap: .875rem;
}

.gap-y-4 {
  row-gap: 1rem;
}

.gap-y-5 {
  row-gap: 1.25rem;
}

.gap-y-7 {
  row-gap: 1.75rem;
}

.space-x-0\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.125rem * var(--tw-space-x-reverse));
  margin-left: calc(.125rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.25rem * var(--tw-space-x-reverse));
  margin-left: calc(.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.5rem * var(--tw-space-x-reverse));
  margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.75rem * var(--tw-space-x-reverse));
  margin-left: calc(.75rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.5rem * var(--tw-space-x-reverse));
  margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(2rem * var(--tw-space-x-reverse));
  margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.25rem * var(--tw-space-y-reverse));
}

.space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.5rem * var(--tw-space-y-reverse));
}

.space-y-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.75rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.75rem * var(--tw-space-y-reverse));
}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.space-y-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
}

.divide-x > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-x-reverse: 0;
  border-right-width: calc(1px * var(--tw-divide-x-reverse));
  border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)));
}

.divide-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-x-reverse: 0;
  border-right-width: calc(2px * var(--tw-divide-x-reverse));
  border-left-width: calc(2px * calc(1 - var(--tw-divide-x-reverse)));
}

.divide-y > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
}

.divide-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(2px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(2px * var(--tw-divide-y-reverse));
}

.divide-black > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(0 0 0 / var(--tw-divide-opacity, 1));
}

.divide-gray-100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(243 244 246 / var(--tw-divide-opacity, 1));
}

.divide-gray-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-divide-opacity, 1));
}

.divide-gray-300 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-divide-opacity, 1));
}

.divide-slate-300 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(203 213 225 / var(--tw-divide-opacity, 1));
}

.divide-slate-50 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(248 250 252 / var(--tw-divide-opacity, 1));
}

.divide-white > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-divide-opacity, 1));
}

.self-start {
  align-self: flex-start;
}

.self-end {
  align-self: flex-end;
}

.self-stretch {
  align-self: stretch;
}

.justify-self-center {
  justify-self: center;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-clip {
  overflow: clip;
}

.overflow-visible {
  overflow: visible;
}

.overflow-scroll {
  overflow: scroll;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.overflow-x-visible {
  overflow-x: visible;
}

.overflow-y-visible {
  overflow-y: visible;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.text-ellipsis {
  text-overflow: ellipsis;
}

.whitespace-normal {
  white-space: normal;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.whitespace-pre-wrap {
  white-space: pre-wrap;
}

.whitespace-break-spaces {
  white-space: break-spaces;
}

.text-wrap {
  text-wrap: wrap;
}

.text-nowrap {
  text-wrap: nowrap;
}

.break-normal {
  overflow-wrap: normal;
  word-break: normal;
}

.break-words {
  overflow-wrap: break-word;
}

.break-all {
  word-break: break-all;
}

.\!rounded {
  border-radius: .25rem !important;
}

.rounded {
  border-radius: .25rem;
}

.rounded-2xl {
  border-radius: 1rem;
}

.rounded-3xl {
  border-radius: 1.5rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-none {
  border-radius: 0;
}

.rounded-sm {
  border-radius: .125rem;
}

.rounded-xl {
  border-radius: .75rem;
}

.rounded-b-md {
  border-bottom-right-radius: .375rem;
  border-bottom-left-radius: .375rem;
}

.rounded-l {
  border-top-left-radius: .25rem;
  border-bottom-left-radius: .25rem;
}

.rounded-l-full {
  border-top-left-radius: 9999px;
  border-bottom-left-radius: 9999px;
}

.rounded-l-none {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.rounded-l-xl {
  border-top-left-radius: .75rem;
  border-bottom-left-radius: .75rem;
}

.rounded-r-full {
  border-top-right-radius: 9999px;
  border-bottom-right-radius: 9999px;
}

.rounded-r-lg {
  border-top-right-radius: .5rem;
  border-bottom-right-radius: .5rem;
}

.rounded-r-none {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rounded-t {
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
}

.rounded-t-md {
  border-top-left-radius: .375rem;
  border-top-right-radius: .375rem;
}

.rounded-bl {
  border-bottom-left-radius: .25rem;
}

.rounded-bl-2xl {
  border-bottom-left-radius: 1rem;
}

.rounded-bl-lg {
  border-bottom-left-radius: .5rem;
}

.rounded-bl-md {
  border-bottom-left-radius: .375rem;
}

.rounded-br-lg {
  border-bottom-right-radius: .5rem;
}

.rounded-br-md {
  border-bottom-right-radius: .375rem;
}

.rounded-tl {
  border-top-left-radius: .25rem;
}

.rounded-tl-md {
  border-top-left-radius: .375rem;
}

.rounded-tl-none {
  border-top-left-radius: 0;
}

.rounded-tl-xl {
  border-top-left-radius: .75rem;
}

.rounded-tr {
  border-top-right-radius: .25rem;
}

.rounded-tr-2xl {
  border-top-right-radius: 1rem;
}

.rounded-tr-md {
  border-top-right-radius: .375rem;
}

.rounded-tr-none {
  border-top-right-radius: 0;
}

.rounded-tr-xl {
  border-top-right-radius: .75rem;
}

.border {
  border-width: 1px;
}

.border-2 {
  border-width: 2px;
}

.border-8 {
  border-width: 8px;
}

.border-\[3px\] {
  border-width: 3px;
}

.border-\[5px\] {
  border-width: 5px;
}

.border-x {
  border-left-width: 1px;
  border-right-width: 1px;
}

.border-x-4 {
  border-left-width: 4px;
  border-right-width: 4px;
}

.border-y {
  border-top-width: 1px;
  border-bottom-width: 1px;
}

.border-y-2 {
  border-top-width: 2px;
  border-bottom-width: 2px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-b-2 {
  border-bottom-width: 2px;
}

.border-b-4, .border-b-\[4px\] {
  border-bottom-width: 4px;
}

.border-b-\[6px\] {
  border-bottom-width: 6px;
}

.border-l {
  border-left-width: 1px;
}

.border-l-2 {
  border-left-width: 2px;
}

.border-l-4 {
  border-left-width: 4px;
}

.border-l-8 {
  border-left-width: 8px;
}

.border-l-\[2\.5px\] {
  border-left-width: 2.5px;
}

.border-l-\[4px\] {
  border-left-width: 4px;
}

.border-l-\[6px\] {
  border-left-width: 6px;
}

.border-r {
  border-right-width: 1px;
}

.border-r-0 {
  border-right-width: 0;
}

.border-r-2 {
  border-right-width: 2px;
}

.border-r-4 {
  border-right-width: 4px;
}

.border-r-\[2\.5px\] {
  border-right-width: 2.5px;
}

.border-r-\[4px\] {
  border-right-width: 4px;
}

.border-t {
  border-top-width: 1px;
}

.border-t-0 {
  border-top-width: 0;
}

.border-t-2 {
  border-top-width: 2px;
}

.border-t-4, .border-t-\[4px\] {
  border-top-width: 4px;
}

.border-t-\[5px\] {
  border-top-width: 5px;
}

.border-t-\[6px\] {
  border-top-width: 6px;
}

.border-solid {
  border-style: solid;
}

.border-dashed {
  border-style: dashed;
}

.border-dotted {
  border-style: dotted;
}

.border-double {
  border-style: double;
}

.border-none {
  border-style: none;
}

.border-\[\#164870\] {
  --tw-border-opacity: 1;
  border-color: rgb(22 72 112 / var(--tw-border-opacity, 1));
}

.border-black {
  --tw-border-opacity: 1;
  border-color: rgb(0 0 0 / var(--tw-border-opacity, 1));
}

.border-blue-400 {
  --tw-border-opacity: 1;
  border-color: rgb(96 165 250 / var(--tw-border-opacity, 1));
}

.border-blue-500 {
  --tw-border-opacity: 1;
  border-color: rgb(59 130 246 / var(--tw-border-opacity, 1));
}

.border-blue-600 {
  --tw-border-opacity: 1;
  border-color: rgb(37 99 235 / var(--tw-border-opacity, 1));
}

.border-blue-700 {
  --tw-border-opacity: 1;
  border-color: rgb(29 78 216 / var(--tw-border-opacity, 1));
}

.border-blue-800 {
  --tw-border-opacity: 1;
  border-color: rgb(30 64 175 / var(--tw-border-opacity, 1));
}

.border-current {
  border-color: currentColor;
}

.border-emerald-400 {
  --tw-border-opacity: 1;
  border-color: rgb(52 211 153 / var(--tw-border-opacity, 1));
}

.border-emerald-500 {
  --tw-border-opacity: 1;
  border-color: rgb(16 185 129 / var(--tw-border-opacity, 1));
}

.border-emerald-800 {
  --tw-border-opacity: 1;
  border-color: rgb(6 95 70 / var(--tw-border-opacity, 1));
}

.border-gray-100 {
  --tw-border-opacity: 1;
  border-color: rgb(243 244 246 / var(--tw-border-opacity, 1));
}

.border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity, 1));
}

.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity, 1));
}

.border-gray-500 {
  --tw-border-opacity: 1;
  border-color: rgb(107 114 128 / var(--tw-border-opacity, 1));
}

.border-gray-600 {
  --tw-border-opacity: 1;
  border-color: rgb(75 85 99 / var(--tw-border-opacity, 1));
}

.border-gray-700 {
  --tw-border-opacity: 1;
  border-color: rgb(55 65 81 / var(--tw-border-opacity, 1));
}

.border-gray-900 {
  --tw-border-opacity: 1;
  border-color: rgb(17 24 39 / var(--tw-border-opacity, 1));
}

.border-green-300 {
  --tw-border-opacity: 1;
  border-color: rgb(134 239 172 / var(--tw-border-opacity, 1));
}

.border-green-400 {
  --tw-border-opacity: 1;
  border-color: rgb(74 222 128 / var(--tw-border-opacity, 1));
}

.border-green-500 {
  --tw-border-opacity: 1;
  border-color: rgb(34 197 94 / var(--tw-border-opacity, 1));
}

.border-green-600 {
  --tw-border-opacity: 1;
  border-color: rgb(22 163 74 / var(--tw-border-opacity, 1));
}

.border-green-700 {
  --tw-border-opacity: 1;
  border-color: rgb(21 128 61 / var(--tw-border-opacity, 1));
}

.border-green-800 {
  --tw-border-opacity: 1;
  border-color: rgb(22 101 52 / var(--tw-border-opacity, 1));
}

.border-green-900 {
  --tw-border-opacity: 1;
  border-color: rgb(20 83 45 / var(--tw-border-opacity, 1));
}

.border-neutral-300 {
  --tw-border-opacity: 1;
  border-color: rgb(212 212 212 / var(--tw-border-opacity, 1));
}

.border-neutral-500 {
  --tw-border-opacity: 1;
  border-color: rgb(115 115 115 / var(--tw-border-opacity, 1));
}

.border-orange-200 {
  --tw-border-opacity: 1;
  border-color: rgb(254 215 170 / var(--tw-border-opacity, 1));
}

.border-primary {
  --tw-border-opacity: 1;
  border-color: rgb(51 65 85 / var(--tw-border-opacity, 1));
}

.border-red-400 {
  --tw-border-opacity: 1;
  border-color: rgb(248 113 113 / var(--tw-border-opacity, 1));
}

.border-red-500 {
  --tw-border-opacity: 1;
  border-color: rgb(239 68 68 / var(--tw-border-opacity, 1));
}

.border-red-700 {
  --tw-border-opacity: 1;
  border-color: rgb(185 28 28 / var(--tw-border-opacity, 1));
}

.border-secondary {
  --tw-border-opacity: 1;
  border-color: rgb(10 90 133 / var(--tw-border-opacity, 1));
}

.border-sky-400 {
  --tw-border-opacity: 1;
  border-color: rgb(56 189 248 / var(--tw-border-opacity, 1));
}

.border-sky-500 {
  --tw-border-opacity: 1;
  border-color: rgb(14 165 233 / var(--tw-border-opacity, 1));
}

.border-sky-600 {
  --tw-border-opacity: 1;
  border-color: rgb(2 132 199 / var(--tw-border-opacity, 1));
}

.border-sky-700 {
  --tw-border-opacity: 1;
  border-color: rgb(3 105 161 / var(--tw-border-opacity, 1));
}

.border-sky-800 {
  --tw-border-opacity: 1;
  border-color: rgb(7 89 133 / var(--tw-border-opacity, 1));
}

.border-sky-900 {
  --tw-border-opacity: 1;
  border-color: rgb(12 74 110 / var(--tw-border-opacity, 1));
}

.border-slate-100 {
  --tw-border-opacity: 1;
  border-color: rgb(241 245 249 / var(--tw-border-opacity, 1));
}

.border-slate-200 {
  --tw-border-opacity: 1;
  border-color: rgb(226 232 240 / var(--tw-border-opacity, 1));
}

.border-slate-300 {
  --tw-border-opacity: 1;
  border-color: rgb(203 213 225 / var(--tw-border-opacity, 1));
}

.border-slate-400 {
  --tw-border-opacity: 1;
  border-color: rgb(148 163 184 / var(--tw-border-opacity, 1));
}

.border-slate-500 {
  --tw-border-opacity: 1;
  border-color: rgb(100 116 139 / var(--tw-border-opacity, 1));
}

.border-slate-600 {
  --tw-border-opacity: 1;
  border-color: rgb(71 85 105 / var(--tw-border-opacity, 1));
}

.border-slate-700 {
  --tw-border-opacity: 1;
  border-color: rgb(51 65 85 / var(--tw-border-opacity, 1));
}

.border-slate-800 {
  --tw-border-opacity: 1;
  border-color: rgb(30 41 59 / var(--tw-border-opacity, 1));
}

.border-transparent {
  border-color: #0000;
}

.border-white {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity, 1));
}

.border-yellow-400 {
  --tw-border-opacity: 1;
  border-color: rgb(250 204 21 / var(--tw-border-opacity, 1));
}

.border-x-transparent {
  border-left-color: #0000;
  border-right-color: #0000;
}

.border-x-white {
  --tw-border-opacity: 1;
  border-left-color: rgb(255 255 255 / var(--tw-border-opacity, 1));
  border-right-color: rgb(255 255 255 / var(--tw-border-opacity, 1));
}

.border-b-blue-500 {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(59 130 246 / var(--tw-border-opacity, 1));
}

.border-b-green-600 {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(22 163 74 / var(--tw-border-opacity, 1));
}

.border-b-primary {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(51 65 85 / var(--tw-border-opacity, 1));
}

.border-b-secondary {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(10 90 133 / var(--tw-border-opacity, 1));
}

.border-b-sky-700 {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(3 105 161 / var(--tw-border-opacity, 1));
}

.border-b-slate-300 {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(203 213 225 / var(--tw-border-opacity, 1));
}

.border-b-transparent {
  border-bottom-color: #0000;
}

.border-b-white {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(255 255 255 / var(--tw-border-opacity, 1));
}

.border-l-amber-400 {
  --tw-border-opacity: 1;
  border-left-color: rgb(251 191 36 / var(--tw-border-opacity, 1));
}

.border-l-gray-300 {
  --tw-border-opacity: 1;
  border-left-color: rgb(209 213 219 / var(--tw-border-opacity, 1));
}

.border-l-gray-400 {
  --tw-border-opacity: 1;
  border-left-color: rgb(156 163 175 / var(--tw-border-opacity, 1));
}

.border-l-green-400 {
  --tw-border-opacity: 1;
  border-left-color: rgb(74 222 128 / var(--tw-border-opacity, 1));
}

.border-l-green-500 {
  --tw-border-opacity: 1;
  border-left-color: rgb(34 197 94 / var(--tw-border-opacity, 1));
}

.border-l-green-900 {
  --tw-border-opacity: 1;
  border-left-color: rgb(20 83 45 / var(--tw-border-opacity, 1));
}

.border-l-lime-500 {
  --tw-border-opacity: 1;
  border-left-color: rgb(132 204 22 / var(--tw-border-opacity, 1));
}

.border-l-orange-300 {
  --tw-border-opacity: 1;
  border-left-color: rgb(253 186 116 / var(--tw-border-opacity, 1));
}

.border-l-primary {
  --tw-border-opacity: 1;
  border-left-color: rgb(51 65 85 / var(--tw-border-opacity, 1));
}

.border-l-red-400 {
  --tw-border-opacity: 1;
  border-left-color: rgb(248 113 113 / var(--tw-border-opacity, 1));
}

.border-l-red-500 {
  --tw-border-opacity: 1;
  border-left-color: rgb(239 68 68 / var(--tw-border-opacity, 1));
}

.border-l-secondary {
  --tw-border-opacity: 1;
  border-left-color: rgb(10 90 133 / var(--tw-border-opacity, 1));
}

.border-l-sky-200 {
  --tw-border-opacity: 1;
  border-left-color: rgb(186 230 253 / var(--tw-border-opacity, 1));
}

.border-l-sky-700 {
  --tw-border-opacity: 1;
  border-left-color: rgb(3 105 161 / var(--tw-border-opacity, 1));
}

.border-l-transparent {
  border-left-color: #0000;
}

.border-r-transparent {
  border-right-color: #0000;
}

.border-t-gray-200 {
  --tw-border-opacity: 1;
  border-top-color: rgb(229 231 235 / var(--tw-border-opacity, 1));
}

.border-t-red-600 {
  --tw-border-opacity: 1;
  border-top-color: rgb(220 38 38 / var(--tw-border-opacity, 1));
}

.border-t-white {
  --tw-border-opacity: 1;
  border-top-color: rgb(255 255 255 / var(--tw-border-opacity, 1));
}

.border-opacity-70 {
  --tw-border-opacity: .7;
}

.\!bg-transparent {
  background-color: #0000 !important;
}

.bg-\[\#021A25\] {
  --tw-bg-opacity: 1;
  background-color: rgb(2 26 37 / var(--tw-bg-opacity, 1));
}

.bg-\[\#164870\] {
  --tw-bg-opacity: 1;
  background-color: rgb(22 72 112 / var(--tw-bg-opacity, 1));
}

.bg-\[\#195888\] {
  --tw-bg-opacity: 1;
  background-color: rgb(25 88 136 / var(--tw-bg-opacity, 1));
}

.bg-\[\#334155\] {
  --tw-bg-opacity: 1;
  background-color: rgb(51 65 85 / var(--tw-bg-opacity, 1));
}

.bg-\[\#CDDDB7\] {
  --tw-bg-opacity: 1;
  background-color: rgb(205 221 183 / var(--tw-bg-opacity, 1));
}

.bg-\[\#E2E8F0\] {
  --tw-bg-opacity: 1;
  background-color: rgb(226 232 240 / var(--tw-bg-opacity, 1));
}

.bg-\[\#F2DA8C\] {
  --tw-bg-opacity: 1;
  background-color: rgb(242 218 140 / var(--tw-bg-opacity, 1));
}

.bg-\[\#F2F7EC\] {
  --tw-bg-opacity: 1;
  background-color: rgb(242 247 236 / var(--tw-bg-opacity, 1));
}

.bg-\[\#F7EDEE\] {
  --tw-bg-opacity: 1;
  background-color: rgb(247 237 238 / var(--tw-bg-opacity, 1));
}

.bg-\[\#F8CBAD\] {
  --tw-bg-opacity: 1;
  background-color: rgb(248 203 173 / var(--tw-bg-opacity, 1));
}

.bg-\[\#FAA5A6\] {
  --tw-bg-opacity: 1;
  background-color: rgb(250 165 166 / var(--tw-bg-opacity, 1));
}

.bg-amber-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(251 191 36 / var(--tw-bg-opacity, 1));
}

.bg-amber-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 251 235 / var(--tw-bg-opacity, 1));
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity, 1));
}

.bg-blue-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(219 234 254 / var(--tw-bg-opacity, 1));
}

.bg-blue-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(191 219 254 / var(--tw-bg-opacity, 1));
}

.bg-blue-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(96 165 250 / var(--tw-bg-opacity, 1));
}

.bg-blue-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(59 130 246 / var(--tw-bg-opacity, 1));
}

.bg-blue-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(37 99 235 / var(--tw-bg-opacity, 1));
}

.bg-blue-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(29 78 216 / var(--tw-bg-opacity, 1));
}

.bg-blue-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(30 58 138 / var(--tw-bg-opacity, 1));
}

.bg-costs {
  --tw-bg-opacity: 1;
  background-color: rgb(212 64 23 / var(--tw-bg-opacity, 1));
}

.bg-cyan-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(8 145 178 / var(--tw-bg-opacity, 1));
}

.bg-cyan-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(14 116 144 / var(--tw-bg-opacity, 1));
}

.bg-earnings {
  --tw-bg-opacity: 1;
  background-color: rgb(111 172 68 / var(--tw-bg-opacity, 1));
}

.bg-emerald-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(110 231 183 / var(--tw-bg-opacity, 1));
}

.bg-emerald-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(52 211 153 / var(--tw-bg-opacity, 1));
}

.bg-emerald-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(16 185 129 / var(--tw-bg-opacity, 1));
}

.bg-emerald-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(6 95 70 / var(--tw-bg-opacity, 1));
}

.bg-financing {
  --tw-bg-opacity: 1;
  background-color: rgb(71 85 105 / var(--tw-bg-opacity, 1));
}

.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity, 1));
}

.bg-gray-100\/50 {
  background-color: #f3f4f680;
}

.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity, 1));
}

.bg-gray-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity, 1));
}

.bg-gray-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(156 163 175 / var(--tw-bg-opacity, 1));
}

.bg-gray-400\/50 {
  background-color: #9ca3af80;
}

.bg-gray-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity, 1));
}

.bg-gray-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(107 114 128 / var(--tw-bg-opacity, 1));
}

.bg-gray-500\/50 {
  background-color: #6b728080;
}

.bg-gray-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity, 1));
}

.bg-gray-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity, 1));
}

.bg-gray-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity, 1));
}

.bg-gray-800\/50 {
  background-color: #1f293780;
}

.bg-gray-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(17 24 39 / var(--tw-bg-opacity, 1));
}

.bg-green-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(187 247 208 / var(--tw-bg-opacity, 1));
}

.bg-green-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(134 239 172 / var(--tw-bg-opacity, 1));
}

.bg-green-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(74 222 128 / var(--tw-bg-opacity, 1));
}

.bg-green-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(34 197 94 / var(--tw-bg-opacity, 1));
}

.bg-green-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(22 163 74 / var(--tw-bg-opacity, 1));
}

.bg-green-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(21 128 61 / var(--tw-bg-opacity, 1));
}

.bg-green-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(20 83 45 / var(--tw-bg-opacity, 1));
}

.bg-lime-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(236 252 203 / var(--tw-bg-opacity, 1));
}

.bg-lime-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(190 242 100 / var(--tw-bg-opacity, 1));
}

.bg-lime-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(132 204 22 / var(--tw-bg-opacity, 1));
}

.bg-lime-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(101 163 13 / var(--tw-bg-opacity, 1));
}

.bg-orange-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 237 213 / var(--tw-bg-opacity, 1));
}

.bg-orange-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 215 170 / var(--tw-bg-opacity, 1));
}

.bg-orange-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(251 146 60 / var(--tw-bg-opacity, 1));
}

.bg-primary {
  --tw-bg-opacity: 1;
  background-color: rgb(51 65 85 / var(--tw-bg-opacity, 1));
}

.bg-red-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 226 226 / var(--tw-bg-opacity, 1));
}

.bg-red-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(252 165 165 / var(--tw-bg-opacity, 1));
}

.bg-red-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(248 113 113 / var(--tw-bg-opacity, 1));
}

.bg-red-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(239 68 68 / var(--tw-bg-opacity, 1));
}

.bg-red-500\/20 {
  background-color: #ef444433;
}

.bg-red-500\/40 {
  background-color: #ef444466;
}

.bg-red-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(220 38 38 / var(--tw-bg-opacity, 1));
}

.bg-red-600\/60 {
  background-color: #dc262699;
}

.bg-red-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(185 28 28 / var(--tw-bg-opacity, 1));
}

.bg-risks {
  --tw-bg-opacity: 1;
  background-color: rgb(13 105 160 / var(--tw-bg-opacity, 1));
}

.bg-secondary {
  --tw-bg-opacity: 1;
  background-color: rgb(10 90 133 / var(--tw-bg-opacity, 1));
}

.bg-secondary\/10 {
  background-color: #0a5a851a;
}

.bg-sky-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(224 242 254 / var(--tw-bg-opacity, 1));
}

.bg-sky-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(186 230 253 / var(--tw-bg-opacity, 1));
}

.bg-sky-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(56 189 248 / var(--tw-bg-opacity, 1));
}

.bg-sky-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(14 165 233 / var(--tw-bg-opacity, 1));
}

.bg-sky-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(2 132 199 / var(--tw-bg-opacity, 1));
}

.bg-sky-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(3 105 161 / var(--tw-bg-opacity, 1));
}

.bg-sky-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(7 89 133 / var(--tw-bg-opacity, 1));
}

.bg-sky-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(12 74 110 / var(--tw-bg-opacity, 1));
}

.bg-slate-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(241 245 249 / var(--tw-bg-opacity, 1));
}

.bg-slate-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(226 232 240 / var(--tw-bg-opacity, 1));
}

.bg-slate-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(203 213 225 / var(--tw-bg-opacity, 1));
}

.bg-slate-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(148 163 184 / var(--tw-bg-opacity, 1));
}

.bg-slate-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(248 250 252 / var(--tw-bg-opacity, 1));
}

.bg-slate-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(100 116 139 / var(--tw-bg-opacity, 1));
}

.bg-slate-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(71 85 105 / var(--tw-bg-opacity, 1));
}

.bg-slate-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(51 65 85 / var(--tw-bg-opacity, 1));
}

.bg-slate-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(30 41 59 / var(--tw-bg-opacity, 1));
}

.bg-slate-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(15 23 42 / var(--tw-bg-opacity, 1));
}

.bg-stone-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(41 37 36 / var(--tw-bg-opacity, 1));
}

.bg-stone-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(28 25 23 / var(--tw-bg-opacity, 1));
}

.bg-transparent {
  background-color: #0000;
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
}

.bg-white\/75 {
  background-color: #ffffffbf;
}

.bg-yellow-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 249 195 / var(--tw-bg-opacity, 1));
}

.bg-yellow-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 240 138 / var(--tw-bg-opacity, 1));
}

.bg-yellow-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(253 224 71 / var(--tw-bg-opacity, 1));
}

.bg-yellow-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(250 204 21 / var(--tw-bg-opacity, 1));
}

.bg-yellow-400\/60 {
  background-color: #facc1599;
}

.bg-yellow-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(234 179 8 / var(--tw-bg-opacity, 1));
}

.bg-zinc-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(228 228 231 / var(--tw-bg-opacity, 1));
}

.bg-opacity-10 {
  --tw-bg-opacity: .1;
}

.bg-opacity-100 {
  --tw-bg-opacity: 1;
}

.bg-opacity-40 {
  --tw-bg-opacity: .4;
}

.bg-opacity-5 {
  --tw-bg-opacity: .05;
}

.bg-opacity-50 {
  --tw-bg-opacity: .5;
}

.bg-opacity-75 {
  --tw-bg-opacity: .75;
}

.bg-gradient-to-b {
  background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
}

.bg-gradient-to-l {
  background-image: linear-gradient(to left, var(--tw-gradient-stops));
}

.from-slate-400 {
  --tw-gradient-from: #94a3b8 var(--tw-gradient-from-position);
  --tw-gradient-to: #94a3b800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-transparent {
  --tw-gradient-from: transparent var(--tw-gradient-from-position);
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.to-black {
  --tw-gradient-to: #000 var(--tw-gradient-to-position);
}

.to-slate-200 {
  --tw-gradient-to: #e2e8f0 var(--tw-gradient-to-position);
}

.bg-contain {
  background-size: contain;
}

.bg-cover {
  background-size: cover;
}

.bg-bottom {
  background-position: bottom;
}

.bg-center {
  background-position: center;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}

.fill-black {
  fill: #000;
}

.fill-blue-600 {
  fill: #2563eb;
}

.fill-gray-800 {
  fill: #1f2937;
}

.fill-slate-400 {
  fill: #94a3b8;
}

.fill-slate-500 {
  fill: #64748b;
}

.fill-transparent {
  fill: #0000;
}

.stroke-\[\#64748b\] {
  stroke: #64748b;
}

.stroke-gray-500 {
  stroke: #6b7280;
}

.stroke-green-500 {
  stroke: #22c55e;
}

.stroke-red-500 {
  stroke: #ef4444;
}

.stroke-slate-700 {
  stroke: #334155;
}

.stroke-yellow-500 {
  stroke: #eab308;
}

.object-contain {
  object-fit: contain;
}

.object-cover {
  object-fit: cover;
}

.object-center {
  object-position: center;
}

.p-0 {
  padding: 0;
}

.p-1 {
  padding: .25rem;
}

.p-1\.5 {
  padding: .375rem;
}

.p-10 {
  padding: 2.5rem;
}

.p-12 {
  padding: 3rem;
}

.p-2 {
  padding: .5rem;
}

.p-2\.5 {
  padding: .625rem;
}

.p-3 {
  padding: .75rem;
}

.p-4 {
  padding: 1rem;
}

.p-5 {
  padding: 1.25rem;
}

.p-6 {
  padding: 1.5rem;
}

.p-8 {
  padding: 2rem;
}

.p-\[3px\] {
  padding: 3px;
}

.p-px {
  padding: 1px;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.px-0\.5 {
  padding-left: .125rem;
  padding-right: .125rem;
}

.px-1 {
  padding-left: .25rem;
  padding-right: .25rem;
}

.px-1\.5 {
  padding-left: .375rem;
  padding-right: .375rem;
}

.px-12 {
  padding-left: 3rem;
  padding-right: 3rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-2\.5 {
  padding-left: .625rem;
  padding-right: .625rem;
}

.px-20 {
  padding-left: 5rem;
  padding-right: 5rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-3\.5 {
  padding-left: .875rem;
  padding-right: .875rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-7 {
  padding-left: 1.75rem;
  padding-right: 1.75rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.px-\[50px\] {
  padding-left: 50px;
  padding-right: 50px;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.py-0\.5 {
  padding-top: .125rem;
  padding-bottom: .125rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-1\.5 {
  padding-top: .375rem;
  padding-bottom: .375rem;
}

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.py-\[0\.10rem\] {
  padding-top: .1rem;
  padding-bottom: .1rem;
}

.py-\[2px\] {
  padding-top: 2px;
  padding-bottom: 2px;
}

.py-px {
  padding-top: 1px;
  padding-bottom: 1px;
}

.pb-0 {
  padding-bottom: 0;
}

.pb-1 {
  padding-bottom: .25rem;
}

.pb-1\.5 {
  padding-bottom: .375rem;
}

.pb-10 {
  padding-bottom: 2.5rem;
}

.pb-12 {
  padding-bottom: 3rem;
}

.pb-2 {
  padding-bottom: .5rem;
}

.pb-2\.5 {
  padding-bottom: .625rem;
}

.pb-20 {
  padding-bottom: 5rem;
}

.pb-3 {
  padding-bottom: .75rem;
}

.pb-32 {
  padding-bottom: 8rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pb-5 {
  padding-bottom: 1.25rem;
}

.pb-6 {
  padding-bottom: 1.5rem;
}

.pb-7 {
  padding-bottom: 1.75rem;
}

.pb-8 {
  padding-bottom: 2rem;
}

.pe-2 {
  padding-inline-end: .5rem;
}

.pe-4 {
  padding-inline-end: 1rem;
}

.pl-0 {
  padding-left: 0;
}

.pl-0\.5 {
  padding-left: .125rem;
}

.pl-1 {
  padding-left: .25rem;
}

.pl-10 {
  padding-left: 2.5rem;
}

.pl-12 {
  padding-left: 3rem;
}

.pl-2 {
  padding-left: .5rem;
}

.pl-24 {
  padding-left: 6rem;
}

.pl-3 {
  padding-left: .75rem;
}

.pl-3\.5 {
  padding-left: .875rem;
}

.pl-4 {
  padding-left: 1rem;
}

.pl-5 {
  padding-left: 1.25rem;
}

.pl-6 {
  padding-left: 1.5rem;
}

.pl-7 {
  padding-left: 1.75rem;
}

.pl-8 {
  padding-left: 2rem;
}

.pl-9 {
  padding-left: 2.25rem;
}

.pl-\[55px\] {
  padding-left: 55px;
}

.pr-0 {
  padding-right: 0;
}

.pr-1 {
  padding-right: .25rem;
}

.pr-1\.5 {
  padding-right: .375rem;
}

.pr-10 {
  padding-right: 2.5rem;
}

.pr-12 {
  padding-right: 3rem;
}

.pr-2 {
  padding-right: .5rem;
}

.pr-24 {
  padding-right: 6rem;
}

.pr-3 {
  padding-right: .75rem;
}

.pr-4 {
  padding-right: 1rem;
}

.pr-5 {
  padding-right: 1.25rem;
}

.pr-6 {
  padding-right: 1.5rem;
}

.pr-8 {
  padding-right: 2rem;
}

.pr-\[40px\] {
  padding-right: 40px;
}

.ps-2 {
  padding-inline-start: .5rem;
}

.ps-3 {
  padding-inline-start: .75rem;
}

.pt-0 {
  padding-top: 0;
}

.pt-1 {
  padding-top: .25rem;
}

.pt-1\.5 {
  padding-top: .375rem;
}

.pt-12 {
  padding-top: 3rem;
}

.pt-16 {
  padding-top: 4rem;
}

.pt-2 {
  padding-top: .5rem;
}

.pt-2\.5 {
  padding-top: .625rem;
}

.pt-20 {
  padding-top: 5rem;
}

.pt-3 {
  padding-top: .75rem;
}

.pt-32 {
  padding-top: 8rem;
}

.pt-36 {
  padding-top: 9rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pt-48 {
  padding-top: 12rem;
}

.pt-5 {
  padding-top: 1.25rem;
}

.pt-6 {
  padding-top: 1.5rem;
}

.pt-8 {
  padding-top: 2rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-start {
  text-align: start;
}

.text-end {
  text-align: end;
}

.indent-6 {
  text-indent: 1.5rem;
}

.align-middle {
  vertical-align: middle;
}

.align-bottom {
  vertical-align: bottom;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-5xl {
  font-size: 3rem;
  line-height: 1;
}

.text-8xl {
  font-size: 6rem;
  line-height: 1;
}

.text-\[10px\] {
  font-size: 10px;
}

.text-\[11px\] {
  font-size: 11px;
}

.text-\[12px\] {
  font-size: 12px;
}

.text-\[13px\] {
  font-size: 13px;
}

.text-\[14px\] {
  font-size: 14px;
}

.text-\[15px\] {
  font-size: 15px;
}

.text-\[16px\] {
  font-size: 16px;
}

.text-\[18px\] {
  font-size: 18px;
}

.text-\[21px\] {
  font-size: 21px;
}

.text-\[22px\] {
  font-size: 22px;
}

.text-\[32px\] {
  font-size: 32px;
}

.text-\[8px\] {
  font-size: 8px;
}

.text-\[9px\] {
  font-size: 9px;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.text-xxs {
  font-size: .625rem;
  line-height: .75rem;
}

.font-black {
  font-weight: 900;
}

.font-bold {
  font-weight: 700;
}

.font-extrabold {
  font-weight: 800;
}

.font-extralight {
  font-weight: 200;
}

.font-light {
  font-weight: 300;
}

.font-medium {
  font-weight: 500;
}

.font-normal {
  font-weight: 400;
}

.font-semibold {
  font-weight: 600;
}

.font-thin {
  font-weight: 100;
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.capitalize {
  text-transform: capitalize;
}

.italic {
  font-style: italic;
}

.\!leading-tight {
  line-height: 1.25 !important;
}

.leading-3 {
  line-height: .75rem;
}

.leading-4 {
  line-height: 1rem;
}

.leading-5 {
  line-height: 1.25rem;
}

.leading-6 {
  line-height: 1.5rem;
}

.leading-7 {
  line-height: 1.75rem;
}

.leading-9 {
  line-height: 2.25rem;
}

.leading-\[10px\] {
  line-height: 10px;
}

.leading-\[13px\] {
  line-height: 13px;
}

.leading-\[15px\] {
  line-height: 15px;
}

.leading-\[18px\] {
  line-height: 18px;
}

.leading-\[22px\] {
  line-height: 22px;
}

.leading-none {
  line-height: 1;
}

.leading-tight {
  line-height: 1.25;
}

.tracking-tight {
  letter-spacing: -.025em;
}

.tracking-tighter {
  letter-spacing: -.05em;
}

.tracking-wide {
  letter-spacing: .025em;
}

.text-\[\#F2DA8C\] {
  --tw-text-opacity: 1;
  color: rgb(242 218 140 / var(--tw-text-opacity, 1));
}

.text-\[\#F8CBAD\] {
  --tw-text-opacity: 1;
  color: rgb(248 203 173 / var(--tw-text-opacity, 1));
}

.text-\[\#FAA5A6\] {
  --tw-text-opacity: 1;
  color: rgb(250 165 166 / var(--tw-text-opacity, 1));
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity, 1));
}

.text-blue-500 {
  --tw-text-opacity: 1;
  color: rgb(59 130 246 / var(--tw-text-opacity, 1));
}

.text-blue-900 {
  --tw-text-opacity: 1;
  color: rgb(30 58 138 / var(--tw-text-opacity, 1));
}

.text-costs {
  --tw-text-opacity: 1;
  color: rgb(212 64 23 / var(--tw-text-opacity, 1));
}

.text-cyan-700 {
  --tw-text-opacity: 1;
  color: rgb(14 116 144 / var(--tw-text-opacity, 1));
}

.text-earnings {
  --tw-text-opacity: 1;
  color: rgb(111 172 68 / var(--tw-text-opacity, 1));
}

.text-emerald-400 {
  --tw-text-opacity: 1;
  color: rgb(52 211 153 / var(--tw-text-opacity, 1));
}

.text-emerald-500 {
  --tw-text-opacity: 1;
  color: rgb(16 185 129 / var(--tw-text-opacity, 1));
}

.text-emerald-600 {
  --tw-text-opacity: 1;
  color: rgb(5 150 105 / var(--tw-text-opacity, 1));
}

.text-financing {
  --tw-text-opacity: 1;
  color: rgb(71 85 105 / var(--tw-text-opacity, 1));
}

.text-gray-100 {
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity, 1));
}

.text-gray-100\/50 {
  color: #f3f4f680;
}

.text-gray-200 {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity, 1));
}

.text-gray-300 {
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity, 1));
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity, 1));
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity, 1));
}

.text-gray-500\/60 {
  color: #6b728099;
}

.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity, 1));
}

.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity, 1));
}

.text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity, 1));
}

.text-green-300 {
  --tw-text-opacity: 1;
  color: rgb(134 239 172 / var(--tw-text-opacity, 1));
}

.text-green-400 {
  --tw-text-opacity: 1;
  color: rgb(74 222 128 / var(--tw-text-opacity, 1));
}

.text-green-500 {
  --tw-text-opacity: 1;
  color: rgb(34 197 94 / var(--tw-text-opacity, 1));
}

.text-green-600 {
  --tw-text-opacity: 1;
  color: rgb(22 163 74 / var(--tw-text-opacity, 1));
}

.text-green-700 {
  --tw-text-opacity: 1;
  color: rgb(21 128 61 / var(--tw-text-opacity, 1));
}

.text-green-800 {
  --tw-text-opacity: 1;
  color: rgb(22 101 52 / var(--tw-text-opacity, 1));
}

.text-green-900 {
  --tw-text-opacity: 1;
  color: rgb(20 83 45 / var(--tw-text-opacity, 1));
}

.text-indigo-200 {
  --tw-text-opacity: 1;
  color: rgb(199 210 254 / var(--tw-text-opacity, 1));
}

.text-indigo-600 {
  --tw-text-opacity: 1;
  color: rgb(79 70 229 / var(--tw-text-opacity, 1));
}

.text-inherit {
  color: inherit;
}

.text-lime-600 {
  --tw-text-opacity: 1;
  color: rgb(101 163 13 / var(--tw-text-opacity, 1));
}

.text-neutral-500 {
  --tw-text-opacity: 1;
  color: rgb(115 115 115 / var(--tw-text-opacity, 1));
}

.text-primary {
  --tw-text-opacity: 1;
  color: rgb(51 65 85 / var(--tw-text-opacity, 1));
}

.text-red-300 {
  --tw-text-opacity: 1;
  color: rgb(252 165 165 / var(--tw-text-opacity, 1));
}

.text-red-400 {
  --tw-text-opacity: 1;
  color: rgb(248 113 113 / var(--tw-text-opacity, 1));
}

.text-red-500 {
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity, 1));
}

.text-red-600 {
  --tw-text-opacity: 1;
  color: rgb(220 38 38 / var(--tw-text-opacity, 1));
}

.text-red-700 {
  --tw-text-opacity: 1;
  color: rgb(185 28 28 / var(--tw-text-opacity, 1));
}

.text-red-800 {
  --tw-text-opacity: 1;
  color: rgb(153 27 27 / var(--tw-text-opacity, 1));
}

.text-red-900 {
  --tw-text-opacity: 1;
  color: rgb(127 29 29 / var(--tw-text-opacity, 1));
}

.text-risks {
  --tw-text-opacity: 1;
  color: rgb(13 105 160 / var(--tw-text-opacity, 1));
}

.text-rose-700 {
  --tw-text-opacity: 1;
  color: rgb(190 18 60 / var(--tw-text-opacity, 1));
}

.text-secondary {
  --tw-text-opacity: 1;
  color: rgb(10 90 133 / var(--tw-text-opacity, 1));
}

.text-sky-200 {
  --tw-text-opacity: 1;
  color: rgb(186 230 253 / var(--tw-text-opacity, 1));
}

.text-sky-500 {
  --tw-text-opacity: 1;
  color: rgb(14 165 233 / var(--tw-text-opacity, 1));
}

.text-sky-600 {
  --tw-text-opacity: 1;
  color: rgb(2 132 199 / var(--tw-text-opacity, 1));
}

.text-sky-700 {
  --tw-text-opacity: 1;
  color: rgb(3 105 161 / var(--tw-text-opacity, 1));
}

.text-sky-800 {
  --tw-text-opacity: 1;
  color: rgb(7 89 133 / var(--tw-text-opacity, 1));
}

.text-sky-900 {
  --tw-text-opacity: 1;
  color: rgb(12 74 110 / var(--tw-text-opacity, 1));
}

.text-sky-900\/60 {
  color: #0c4a6e99;
}

.text-slate-100 {
  --tw-text-opacity: 1;
  color: rgb(241 245 249 / var(--tw-text-opacity, 1));
}

.text-slate-200 {
  --tw-text-opacity: 1;
  color: rgb(226 232 240 / var(--tw-text-opacity, 1));
}

.text-slate-300 {
  --tw-text-opacity: 1;
  color: rgb(203 213 225 / var(--tw-text-opacity, 1));
}

.text-slate-400 {
  --tw-text-opacity: 1;
  color: rgb(148 163 184 / var(--tw-text-opacity, 1));
}

.text-slate-500 {
  --tw-text-opacity: 1;
  color: rgb(100 116 139 / var(--tw-text-opacity, 1));
}

.text-slate-600 {
  --tw-text-opacity: 1;
  color: rgb(71 85 105 / var(--tw-text-opacity, 1));
}

.text-slate-700 {
  --tw-text-opacity: 1;
  color: rgb(51 65 85 / var(--tw-text-opacity, 1));
}

.text-slate-800 {
  --tw-text-opacity: 1;
  color: rgb(30 41 59 / var(--tw-text-opacity, 1));
}

.text-slate-900 {
  --tw-text-opacity: 1;
  color: rgb(15 23 42 / var(--tw-text-opacity, 1));
}

.text-slate-900\/20 {
  color: #0f172a33;
}

.text-stone-600 {
  --tw-text-opacity: 1;
  color: rgb(87 83 78 / var(--tw-text-opacity, 1));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

.text-yellow-400 {
  --tw-text-opacity: 1;
  color: rgb(250 204 21 / var(--tw-text-opacity, 1));
}

.text-yellow-500 {
  --tw-text-opacity: 1;
  color: rgb(234 179 8 / var(--tw-text-opacity, 1));
}

.text-yellow-600 {
  --tw-text-opacity: 1;
  color: rgb(202 138 4 / var(--tw-text-opacity, 1));
}

.text-yellow-700 {
  --tw-text-opacity: 1;
  color: rgb(161 98 7 / var(--tw-text-opacity, 1));
}

.text-zinc-600 {
  --tw-text-opacity: 1;
  color: rgb(82 82 91 / var(--tw-text-opacity, 1));
}

.underline {
  text-decoration-line: underline;
}

.line-through {
  text-decoration-line: line-through;
}

.accent-gray-800 {
  accent-color: #1f2937;
}

.opacity-0 {
  opacity: 0;
}

.opacity-10 {
  opacity: .1;
}

.opacity-100 {
  opacity: 1;
}

.opacity-25 {
  opacity: .25;
}

.opacity-30 {
  opacity: .3;
}

.opacity-40 {
  opacity: .4;
}

.opacity-50 {
  opacity: .5;
}

.opacity-60 {
  opacity: .6;
}

.opacity-70 {
  opacity: .7;
}

.opacity-80 {
  opacity: .8;
}

.shadow {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-\[inset_0px_0px_0px_1px\] {
  --tw-shadow: inset 0px 0px 0px 1px;
  --tw-shadow-colored: inset 0px 0px 0px 1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-\[inset_1px_1px_3px_0px_rgba\(51\,65\,85\,0\.2\)\] {
  --tw-shadow: inset 1px 1px 3px 0px #33415533;
  --tw-shadow-colored: inset 1px 1px 3px 0px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-inner {
  --tw-shadow: inset 0 2px 4px 0 #0000000d;
  --tw-shadow-colored: inset 0 2px 4px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-none {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-sm {
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-xl {
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-green-500 {
  --tw-shadow-color: #22c55e;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-500 {
  --tw-shadow-color: #ef4444;
  --tw-shadow: var(--tw-shadow-colored);
}

.outline-none {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.outline-0 {
  outline-width: 0;
}

.outline-secondary {
  outline-color: #0a5a85;
}

.ring-1 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-black {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(0 0 0 / var(--tw-ring-opacity, 1));
}

.ring-opacity-5 {
  --tw-ring-opacity: .05;
}

.ring-offset-2 {
  --tw-ring-offset-width: 2px;
}

.blur {
  --tw-blur: blur(8px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.drop-shadow {
  --tw-drop-shadow: drop-shadow(0 1px 2px #0000001a) drop-shadow(0 1px 1px #0000000f);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.drop-shadow-md {
  --tw-drop-shadow: drop-shadow(0 4px 3px #00000012) drop-shadow(0 2px 2px #0000000f);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-\[margin\] {
  transition-property: margin;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-\[top\] {
  transition-property: top;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-colors {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-height {
  transition-property: height;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-opacity {
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-shadow {
  transition-property: box-shadow;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-transform {
  transition-property: transform;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.delay-1000 {
  transition-delay: 1s;
}

.duration-100 {
  transition-duration: .1s;
}

.duration-150 {
  transition-duration: .15s;
}

.duration-200 {
  transition-duration: .2s;
}

.duration-300 {
  transition-duration: .3s;
}

.duration-500 {
  transition-duration: .5s;
}

.duration-75 {
  transition-duration: 75ms;
}

.ease-in {
  transition-timing-function: cubic-bezier(.4, 0, 1, 1);
}

.ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

.will-change-contents {
  will-change: contents;
}

.will-change-transform {
  will-change: transform;
}

.\[text-shadow\:_1_1px_0_rgb\(0_0_0_\/_90\%\)\] {
  text-shadow: 1px 1px #000000e6;
}

body {
  font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-font-smoothing: subpixel-antialiased;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  overflow: hidden;
}

input.fake-mt:focus-within ~ label, input.fake-mt:not([value=""]) ~ label, textarea.fake-mt:focus-within ~ label, textarea.fake-mt:not(:empty) ~ label {
  --tw-translate-y: -.75rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  font-size: .75rem;
  line-height: 1rem;
}

div.popper-ref-hidden[data-popper-reference-hidden="true"] {
  visibility: hidden;
  pointer-events: none;
}

.hide-scrollbars::-webkit-scrollbar {
  display: none;
}

.hide-scrollbars {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.formula-icon__highlight g[id="bg"] {
  fill: #005c89;
}

.formula-icon__highlight g[id="bg"] rect[x="0.5"][y="0.5"] {
  display: none;
}

#editing, #highlighting {
  white-space: break-spaces;
  border: 0;
  grid-area: 1 / 1 / 2 / 2;
  width: 100%;
  height: 100%;
  max-height: 16em;
  margin: 0;
  padding: 5px;
  overflow: auto;
}

#editing, #highlighting, #highlighting-content {
  font-family: Consolas, Monaco, Andale Mono, Ubuntu Mono, monospace;
  font-size: 1em;
  line-height: 1.5em;
}

.grow-wrap {
  max-height: 16em;
}

#editing {
  z-index: 1;
  color: #0000;
  caret-color: #000;
  resize: none;
  background: none;
}

#highlighting {
  z-index: 0;
  word-wrap: break-word;
}

pre, code {
  white-space: pre-wrap !important;
  word-wrap: break-word !important;
}

:not(pre) > code[class*="language-"], pre[class*="language-"] {
  background: #fff !important;
}

.language-excel-formula .function-name.builtin {
  color: #81a84c;
  font-weight: bold;
}

.language-excel-formula .operator, .language-excel-formula .punctuation {
  color: #81a84c;
}

.language-excel-formula .number {
  color: #9f1c5f;
}

.language-excel-formula .string, .language-excel-formula .comma {
  color: #000;
}

.react-pdf__Page .annotationLayer {
  max-width: 100%;
  overflow: hidden;
}

@keyframes inAnimation {
  0% {
    opacity: 0;
    visibility: hidden;
  }

  100% {
    opacity: 1;
    visibility: visible;
  }
}

@keyframes outAnimation {
  0% {
    opacity: 1;
    visibility: visible;
  }

  100% {
    opacity: 0;
    visibility: hidden;
  }
}

.pdf-export-show {
  display: none;
}

.pdf-export .pdf-export-hidden {
  display: none !important;
}

.pdf-export .pdf-export-show {
  display: block !important;
}

.pdf-export .before\:shadow-lg:before {
  box-shadow: none;
}

.pdf-export .pdf-export-overflow-hidden {
  overflow: hidden !important;
}

.col-span-all {
  column-span: all;
}

.ics-report-viewer #StiViewer .stiJsViewerToolBar .stiJsViewerToolBarTable {
  padding: 10px 10px 0;
}

.ics-report-viewer #StiViewer {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: 0 0 .375rem .375rem;
  background: none !important;
}

.pac-container {
  box-shadow: none;
  padding-bottom: 5px;
  padding-right: 8px;
}

.react-grid-item.react-grid-placeholder {
  background-color: #4ade80 !important;
}

.react-grid-item.react-grid-placeholder.resize-unavailable {
  background-color: #d44017 !important;
}

#invoicesReport .stiJsViewerReportPanel {
  margin-bottom: 10px !important;
  margin-left: 2rem !important;
  margin-right: 2rem !important;
  padding-bottom: 20px !important;
  overflow: auto !important;
}

#invoicesReport .stiJsViewerPage {
  border: none !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding: 0 !important;
}

.before\:pointer-events-auto:before {
  content: var(--tw-content);
  pointer-events: auto;
}

.before\:absolute:before {
  content: var(--tw-content);
  position: absolute;
}

.before\:-left-0\.5:before {
  content: var(--tw-content);
  left: -.125rem;
}

.before\:-left-2\.5:before {
  content: var(--tw-content);
  left: -.625rem;
}

.before\:-left-px:before {
  content: var(--tw-content);
  left: -1px;
}

.before\:-right-0\.5:before {
  content: var(--tw-content);
  right: -.125rem;
}

.before\:-top-\[3px\]:before {
  content: var(--tw-content);
  top: -3px;
}

.before\:bottom-0:before {
  content: var(--tw-content);
  bottom: 0;
}

.before\:left-0:before {
  content: var(--tw-content);
  left: 0;
}

.before\:left-0\.5:before {
  content: var(--tw-content);
  left: .125rem;
}

.before\:left-1\/2:before {
  content: var(--tw-content);
  left: 50%;
}

.before\:top-0:before {
  content: var(--tw-content);
  top: 0;
}

.before\:top-1\/2:before {
  content: var(--tw-content);
  top: 50%;
}

.before\:-z-10:before {
  content: var(--tw-content);
  z-index: -10;
}

.before\:z-0:before {
  content: var(--tw-content);
  z-index: 0;
}

.before\:z-10:before {
  content: var(--tw-content);
  z-index: 10;
}

.before\:z-20:before {
  content: var(--tw-content);
  z-index: 20;
}

.before\:z-30:before {
  content: var(--tw-content);
  z-index: 30;
}

.before\:z-\[1\]:before {
  content: var(--tw-content);
  z-index: 1;
}

.before\:mx-2\.5:before {
  content: var(--tw-content);
  margin-left: .625rem;
  margin-right: .625rem;
}

.before\:-mt-2:before {
  content: var(--tw-content);
  margin-top: -.5rem;
}

.before\:block:before {
  content: var(--tw-content);
  display: block;
}

.before\:inline-block:before {
  content: var(--tw-content);
  display: inline-block;
}

.before\:h-0:before {
  content: var(--tw-content);
  height: 0;
}

.before\:h-0\.5:before {
  content: var(--tw-content);
  height: .125rem;
}

.before\:h-1:before {
  content: var(--tw-content);
  height: .25rem;
}

.before\:h-1\/2:before {
  content: var(--tw-content);
  height: 50%;
}

.before\:h-11:before {
  content: var(--tw-content);
  height: 2.75rem;
}

.before\:h-2\.5:before {
  content: var(--tw-content);
  height: .625rem;
}

.before\:h-4:before {
  content: var(--tw-content);
  height: 1rem;
}

.before\:h-5:before {
  content: var(--tw-content);
  height: 1.25rem;
}

.before\:h-\[0\.5625rem\]:before {
  content: var(--tw-content);
  height: .5625rem;
}

.before\:h-full:before {
  content: var(--tw-content);
  height: 100%;
}

.before\:h-px:before {
  content: var(--tw-content);
  height: 1px;
}

.before\:min-h-\[0\.625rem\]:before {
  content: var(--tw-content);
  min-height: .625rem;
}

.before\:min-h-full:before {
  content: var(--tw-content);
  min-height: 100%;
}

.before\:w-0:before {
  content: var(--tw-content);
  width: 0;
}

.before\:w-1:before {
  content: var(--tw-content);
  width: .25rem;
}

.before\:w-2\.5:before {
  content: var(--tw-content);
  width: .625rem;
}

.before\:w-3:before {
  content: var(--tw-content);
  width: .75rem;
}

.before\:w-5:before {
  content: var(--tw-content);
  width: 1.25rem;
}

.before\:w-\[0\.5625rem\]:before {
  content: var(--tw-content);
  width: .5625rem;
}

.before\:w-\[2px\]:before {
  content: var(--tw-content);
  width: 2px;
}

.before\:w-\[calc\(100\%-4px\)\]:before {
  content: var(--tw-content);
  width: calc(100% - 4px);
}

.before\:w-full:before {
  content: var(--tw-content);
  width: 100%;
}

.before\:w-px:before {
  content: var(--tw-content);
  width: 1px;
}

.before\:min-w-full:before {
  content: var(--tw-content);
  min-width: 100%;
}

.before\:flex-none:before {
  content: var(--tw-content);
  flex: none;
}

.before\:-translate-x-1\/2:before {
  content: var(--tw-content);
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.before\:-translate-x-2\.5:before {
  content: var(--tw-content);
  --tw-translate-x: -.625rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.before\:-translate-x-3:before {
  content: var(--tw-content);
  --tw-translate-x: -.75rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.before\:-translate-y-1\/2:before {
  content: var(--tw-content);
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.before\:-translate-y-3:before {
  content: var(--tw-content);
  --tw-translate-y: -.75rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.before\:-translate-y-\[3px\]:before {
  content: var(--tw-content);
  --tw-translate-y: -3px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.before\:rotate-180:before {
  content: var(--tw-content);
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.before\:cursor-pointer:before {
  content: var(--tw-content);
  cursor: pointer;
}

.before\:overflow-hidden:before {
  content: var(--tw-content);
  overflow: hidden;
}

.before\:rounded-full:before {
  content: var(--tw-content);
  border-radius: 9999px;
}

.before\:rounded-md:before {
  content: var(--tw-content);
  border-radius: .375rem;
}

.before\:border-2:before {
  content: var(--tw-content);
  border-width: 2px;
}

.before\:border-b-\[23px\]:before {
  content: var(--tw-content);
  border-bottom-width: 23px;
}

.before\:border-l:before {
  content: var(--tw-content);
  border-left-width: 1px;
}

.before\:border-l-2:before {
  content: var(--tw-content);
  border-left-width: 2px;
}

.before\:border-l-\[18px\]:before {
  content: var(--tw-content);
  border-left-width: 18px;
}

.before\:border-r-0:before {
  content: var(--tw-content);
  border-right-width: 0;
}

.before\:border-t-\[23px\]:before {
  content: var(--tw-content);
  border-top-width: 23px;
}

.before\:border-current:before {
  content: var(--tw-content);
  border-color: currentColor;
}

.before\:border-gray-200:before {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity, 1));
}

.before\:border-green-700:before {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(21 128 61 / var(--tw-border-opacity, 1));
}

.before\:border-red-700:before {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(185 28 28 / var(--tw-border-opacity, 1));
}

.before\:border-secondary:before {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(10 90 133 / var(--tw-border-opacity, 1));
}

.before\:border-slate-300:before {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(203 213 225 / var(--tw-border-opacity, 1));
}

.before\:border-slate-500:before {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(100 116 139 / var(--tw-border-opacity, 1));
}

.before\:border-b-transparent:before {
  content: var(--tw-content);
  border-bottom-color: #0000;
}

.before\:border-l-gray-100:before {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-left-color: rgb(243 244 246 / var(--tw-border-opacity, 1));
}

.before\:border-l-white:before {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-left-color: rgb(255 255 255 / var(--tw-border-opacity, 1));
}

.before\:border-r-transparent:before {
  content: var(--tw-content);
  border-right-color: #0000;
}

.before\:border-t-transparent:before {
  content: var(--tw-content);
  border-top-color: #0000;
}

.before\:bg-emerald-400:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(52 211 153 / var(--tw-bg-opacity, 1));
}

.before\:bg-gray-200:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity, 1));
}

.before\:bg-neutral-300:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(212 212 212 / var(--tw-bg-opacity, 1));
}

.before\:bg-secondary:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(10 90 133 / var(--tw-bg-opacity, 1));
}

.before\:bg-slate-100:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(241 245 249 / var(--tw-bg-opacity, 1));
}

.before\:bg-slate-300:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(203 213 225 / var(--tw-bg-opacity, 1));
}

.before\:bg-slate-400:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(148 163 184 / var(--tw-bg-opacity, 1));
}

.before\:bg-slate-600:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(71 85 105 / var(--tw-bg-opacity, 1));
}

.before\:bg-transparent:before {
  content: var(--tw-content);
  background-color: #0000;
}

.before\:bg-white:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
}

.before\:p-px:before {
  content: var(--tw-content);
  padding: 1px;
}

.before\:pr-1:before {
  content: var(--tw-content);
  padding-right: .25rem;
}

.before\:opacity-0:before {
  content: var(--tw-content);
  opacity: 0;
}

.before\:shadow:before {
  content: var(--tw-content);
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.before\:shadow-lg:before {
  content: var(--tw-content);
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.before\:shadow-md:before {
  content: var(--tw-content);
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.before\:transition-all:before {
  content: var(--tw-content);
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.before\:transition-colors:before {
  content: var(--tw-content);
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.before\:transition-opacity:before {
  content: var(--tw-content);
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.before\:duration-300:before {
  content: var(--tw-content);
  transition-duration: .3s;
}

.before\:content-\[\"\"\]:before, .before\:content-\[\'\'\]:before {
  --tw-content: "";
  content: var(--tw-content);
}

.before\:content-\[\'\=\'\]:before {
  --tw-content: "=";
  content: var(--tw-content);
}

.after\:pointer-events-auto:after {
  content: var(--tw-content);
  pointer-events: auto;
}

.after\:absolute:after {
  content: var(--tw-content);
  position: absolute;
}

.after\:-bottom-1:after {
  content: var(--tw-content);
  bottom: -.25rem;
}

.after\:-left-\[15px\]:after {
  content: var(--tw-content);
  left: -15px;
}

.after\:-right-\[14px\]:after {
  content: var(--tw-content);
  right: -14px;
}

.after\:-right-\[15px\]:after {
  content: var(--tw-content);
  right: -15px;
}

.after\:-right-px:after {
  content: var(--tw-content);
  right: -1px;
}

.after\:left-0:after {
  content: var(--tw-content);
  left: 0;
}

.after\:left-full:after {
  content: var(--tw-content);
  left: 100%;
}

.after\:top-0:after {
  content: var(--tw-content);
  top: 0;
}

.after\:top-1\/2:after {
  content: var(--tw-content);
  top: 50%;
}

.after\:z-10:after {
  content: var(--tw-content);
  z-index: 10;
}

.after\:z-20:after {
  content: var(--tw-content);
  z-index: 20;
}

.after\:z-30:after {
  content: var(--tw-content);
  z-index: 30;
}

.after\:z-\[1\]:after {
  content: var(--tw-content);
  z-index: 1;
}

.after\:z-\[2\]:after {
  content: var(--tw-content);
  z-index: 2;
}

.after\:mx-2\.5:after {
  content: var(--tw-content);
  margin-left: .625rem;
  margin-right: .625rem;
}

.after\:block:after {
  content: var(--tw-content);
  display: block;
}

.after\:h-0:after {
  content: var(--tw-content);
  height: 0;
}

.after\:h-2\.5:after {
  content: var(--tw-content);
  height: .625rem;
}

.after\:h-\[0\.5625rem\]:after {
  content: var(--tw-content);
  height: .5625rem;
}

.after\:h-full:after {
  content: var(--tw-content);
  height: 100%;
}

.after\:h-px:after {
  content: var(--tw-content);
  height: 1px;
}

.after\:w-0:after {
  content: var(--tw-content);
  width: 0;
}

.after\:w-0\.5:after {
  content: var(--tw-content);
  width: .125rem;
}

.after\:w-2\.5:after {
  content: var(--tw-content);
  width: .625rem;
}

.after\:w-5:after {
  content: var(--tw-content);
  width: 1.25rem;
}

.after\:w-\[0\.5625rem\]:after {
  content: var(--tw-content);
  width: .5625rem;
}

.after\:w-\[2px\]:after {
  content: var(--tw-content);
  width: 2px;
}

.after\:w-full:after {
  content: var(--tw-content);
  width: 100%;
}

.after\:w-px:after {
  content: var(--tw-content);
  width: 1px;
}

.after\:-translate-x-1\.5:after {
  content: var(--tw-content);
  --tw-translate-x: -.375rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.after\:-translate-x-1\/2:after {
  content: var(--tw-content);
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.after\:-translate-y-1\.5:after {
  content: var(--tw-content);
  --tw-translate-y: -.375rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.after\:-translate-y-1\/2:after {
  content: var(--tw-content);
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.after\:translate-x-2\.5:after {
  content: var(--tw-content);
  --tw-translate-x: .625rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.after\:rotate-180:after {
  content: var(--tw-content);
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.after\:cursor-pointer:after {
  content: var(--tw-content);
  cursor: pointer;
}

.after\:overflow-hidden:after {
  content: var(--tw-content);
  overflow: hidden;
}

.after\:rounded-full:after {
  content: var(--tw-content);
  border-radius: 9999px;
}

.after\:rounded-md:after {
  content: var(--tw-content);
  border-radius: .375rem;
}

.after\:border-2:after {
  content: var(--tw-content);
  border-width: 2px;
}

.after\:border-b-\[20px\]:after {
  content: var(--tw-content);
  border-bottom-width: 20px;
}

.after\:border-l-\[15px\]:after {
  content: var(--tw-content);
  border-left-width: 15px;
}

.after\:border-r:after {
  content: var(--tw-content);
  border-right-width: 1px;
}

.after\:border-r-0:after {
  content: var(--tw-content);
  border-right-width: 0;
}

.after\:border-t-\[20px\]:after {
  content: var(--tw-content);
  border-top-width: 20px;
}

.after\:border-current:after {
  content: var(--tw-content);
  border-color: currentColor;
}

.after\:border-green-700:after {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(21 128 61 / var(--tw-border-opacity, 1));
}

.after\:border-red-700:after {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(185 28 28 / var(--tw-border-opacity, 1));
}

.after\:border-secondary:after {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(10 90 133 / var(--tw-border-opacity, 1));
}

.after\:border-slate-300:after {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(203 213 225 / var(--tw-border-opacity, 1));
}

.after\:border-slate-500:after {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(100 116 139 / var(--tw-border-opacity, 1));
}

.after\:border-b-transparent:after {
  content: var(--tw-content);
  border-bottom-color: #0000;
}

.after\:border-l-gray-100:after {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-left-color: rgb(243 244 246 / var(--tw-border-opacity, 1));
}

.after\:border-l-gray-300:after {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-left-color: rgb(209 213 219 / var(--tw-border-opacity, 1));
}

.after\:border-l-gray-400:after {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-left-color: rgb(156 163 175 / var(--tw-border-opacity, 1));
}

.after\:border-l-secondary:after {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-left-color: rgb(10 90 133 / var(--tw-border-opacity, 1));
}

.after\:border-r-transparent:after {
  content: var(--tw-content);
  border-right-color: #0000;
}

.after\:border-t-transparent:after {
  content: var(--tw-content);
  border-top-color: #0000;
}

.after\:bg-gray-100:after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity, 1));
}

.after\:bg-gray-200:after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity, 1));
}

.after\:bg-primary:after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(51 65 85 / var(--tw-bg-opacity, 1));
}

.after\:bg-secondary:after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(10 90 133 / var(--tw-bg-opacity, 1));
}

.after\:bg-slate-300:after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(203 213 225 / var(--tw-bg-opacity, 1));
}

.after\:bg-slate-50:after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(248 250 252 / var(--tw-bg-opacity, 1));
}

.after\:bg-white:after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
}

.after\:p-px:after {
  content: var(--tw-content);
  padding: 1px;
}

.after\:shadow:after {
  content: var(--tw-content);
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.after\:transition-all:after {
  content: var(--tw-content);
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.after\:transition-colors:after {
  content: var(--tw-content);
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.after\:duration-300:after {
  content: var(--tw-content);
  transition-duration: .3s;
}

.after\:content-\[\"\"\]:after, .after\:content-\[\'\'\]:after {
  --tw-content: "";
  content: var(--tw-content);
}

.first\:rounded-l-full:first-child {
  border-top-left-radius: 9999px;
  border-bottom-left-radius: 9999px;
}

.first\:rounded-r-none:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.first\:pl-5:first-child {
  padding-left: 1.25rem;
}

.first\:before\:content-none:first-child:before {
  --tw-content: none;
  content: var(--tw-content);
}

.last\:rounded-l-none:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.last\:rounded-r-full:last-child {
  border-top-right-radius: 9999px;
  border-bottom-right-radius: 9999px;
}

.last\:border-b-0:last-child {
  border-bottom-width: 0;
}

.last\:pr-5:last-child {
  padding-right: 1.25rem;
}

.last\:after\:content-none:last-child:after {
  --tw-content: none;
  content: var(--tw-content);
}

.only\:rounded-full:only-child {
  border-radius: 9999px;
}

.even\:bg-slate-600:nth-child(2n) {
  --tw-bg-opacity: 1;
  background-color: rgb(71 85 105 / var(--tw-bg-opacity, 1));
}

.checked\:bg-cyan-700\/50:checked {
  background-color: #0e749080;
}

.checked\:before\:translate-x-\[16px\]:checked:before {
  content: var(--tw-content);
  --tw-translate-x: 16px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.checked\:before\:bg-cyan-700:checked:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(14 116 144 / var(--tw-bg-opacity, 1));
}

.focus-within\:border:focus-within {
  border-width: 1px;
}

.focus-within\:border-secondary:focus-within {
  --tw-border-opacity: 1;
  border-color: rgb(10 90 133 / var(--tw-border-opacity, 1));
}

.hover\:w-7:hover {
  width: 1.75rem;
}

.hover\:scale-105:hover {
  --tw-scale-x: 1.05;
  --tw-scale-y: 1.05;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.hover\:scale-110:hover {
  --tw-scale-x: 1.1;
  --tw-scale-y: 1.1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.hover\:scale-95:hover {
  --tw-scale-x: .95;
  --tw-scale-y: .95;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.hover\:cursor-pointer:hover {
  cursor: pointer;
}

.hover\:border-2:hover {
  border-width: 2px;
}

.hover\:border-gray-200:hover {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity, 1));
}

.hover\:border-gray-300:hover {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity, 1));
}

.hover\:border-gray-400:hover {
  --tw-border-opacity: 1;
  border-color: rgb(156 163 175 / var(--tw-border-opacity, 1));
}

.hover\:border-secondary:hover {
  --tw-border-opacity: 1;
  border-color: rgb(10 90 133 / var(--tw-border-opacity, 1));
}

.hover\:border-sky-800:hover {
  --tw-border-opacity: 1;
  border-color: rgb(7 89 133 / var(--tw-border-opacity, 1));
}

.hover\:border-slate-300:hover {
  --tw-border-opacity: 1;
  border-color: rgb(203 213 225 / var(--tw-border-opacity, 1));
}

.hover\:border-slate-400:hover {
  --tw-border-opacity: 1;
  border-color: rgb(148 163 184 / var(--tw-border-opacity, 1));
}

.hover\:bg-blue-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(219 234 254 / var(--tw-bg-opacity, 1));
}

.hover\:bg-blue-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(191 219 254 / var(--tw-bg-opacity, 1));
}

.hover\:bg-emerald-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(52 211 153 / var(--tw-bg-opacity, 1));
}

.hover\:bg-gray-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity, 1));
}

.hover\:bg-gray-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity, 1));
}

.hover\:bg-gray-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity, 1));
}

.hover\:bg-gray-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity, 1));
}

.hover\:bg-neutral-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(245 245 245 / var(--tw-bg-opacity, 1));
}

.hover\:bg-red-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(239 68 68 / var(--tw-bg-opacity, 1));
}

.hover\:bg-red-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(220 38 38 / var(--tw-bg-opacity, 1));
}

.hover\:bg-secondary\/10:hover {
  background-color: #0a5a851a;
}

.hover\:bg-sky-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(224 242 254 / var(--tw-bg-opacity, 1));
}

.hover\:bg-sky-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(14 165 233 / var(--tw-bg-opacity, 1));
}

.hover\:bg-sky-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(7 89 133 / var(--tw-bg-opacity, 1));
}

.hover\:bg-slate-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(241 245 249 / var(--tw-bg-opacity, 1));
}

.hover\:bg-slate-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(226 232 240 / var(--tw-bg-opacity, 1));
}

.hover\:bg-slate-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(203 213 225 / var(--tw-bg-opacity, 1));
}

.hover\:bg-slate-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(248 250 252 / var(--tw-bg-opacity, 1));
}

.hover\:bg-slate-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(100 116 139 / var(--tw-bg-opacity, 1));
}

.hover\:bg-slate-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(71 85 105 / var(--tw-bg-opacity, 1));
}

.hover\:bg-opacity-10:hover {
  --tw-bg-opacity: .1;
}

.hover\:bg-opacity-20:hover {
  --tw-bg-opacity: .2;
}

.hover\:fill-slate-500:hover {
  fill: #64748b;
}

.hover\:text-black:hover {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity, 1));
}

.hover\:text-blue-500:hover {
  --tw-text-opacity: 1;
  color: rgb(59 130 246 / var(--tw-text-opacity, 1));
}

.hover\:text-blue-600:hover {
  --tw-text-opacity: 1;
  color: rgb(37 99 235 / var(--tw-text-opacity, 1));
}

.hover\:text-gray-300:hover {
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity, 1));
}

.hover\:text-gray-500:hover {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity, 1));
}

.hover\:text-gray-600:hover {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
}

.hover\:text-gray-700:hover {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity, 1));
}

.hover\:text-gray-800:hover {
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity, 1));
}

.hover\:text-red-300:hover {
  --tw-text-opacity: 1;
  color: rgb(252 165 165 / var(--tw-text-opacity, 1));
}

.hover\:text-red-400:hover {
  --tw-text-opacity: 1;
  color: rgb(248 113 113 / var(--tw-text-opacity, 1));
}

.hover\:text-red-700:hover {
  --tw-text-opacity: 1;
  color: rgb(185 28 28 / var(--tw-text-opacity, 1));
}

.hover\:text-secondary:hover {
  --tw-text-opacity: 1;
  color: rgb(10 90 133 / var(--tw-text-opacity, 1));
}

.hover\:text-sky-300:hover {
  --tw-text-opacity: 1;
  color: rgb(125 211 252 / var(--tw-text-opacity, 1));
}

.hover\:text-sky-500:hover {
  --tw-text-opacity: 1;
  color: rgb(14 165 233 / var(--tw-text-opacity, 1));
}

.hover\:text-sky-700:hover {
  --tw-text-opacity: 1;
  color: rgb(3 105 161 / var(--tw-text-opacity, 1));
}

.hover\:text-sky-800:hover {
  --tw-text-opacity: 1;
  color: rgb(7 89 133 / var(--tw-text-opacity, 1));
}

.hover\:text-slate-300:hover {
  --tw-text-opacity: 1;
  color: rgb(203 213 225 / var(--tw-text-opacity, 1));
}

.hover\:text-slate-400:hover {
  --tw-text-opacity: 1;
  color: rgb(148 163 184 / var(--tw-text-opacity, 1));
}

.hover\:text-slate-500:hover {
  --tw-text-opacity: 1;
  color: rgb(100 116 139 / var(--tw-text-opacity, 1));
}

.hover\:text-slate-600:hover {
  --tw-text-opacity: 1;
  color: rgb(71 85 105 / var(--tw-text-opacity, 1));
}

.hover\:text-slate-700:hover {
  --tw-text-opacity: 1;
  color: rgb(51 65 85 / var(--tw-text-opacity, 1));
}

.hover\:text-white:hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

.hover\:opacity-50:hover {
  opacity: .5;
}

.hover\:opacity-60:hover {
  opacity: .6;
}

.hover\:opacity-70:hover {
  opacity: .7;
}

.hover\:opacity-75:hover {
  opacity: .75;
}

.hover\:opacity-80:hover {
  opacity: .8;
}

.hover\:shadow-inner:hover {
  --tw-shadow: inset 0 2px 4px 0 #0000000d;
  --tw-shadow-colored: inset 0 2px 4px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-md:hover {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:border-sky-700:focus {
  --tw-border-opacity: 1;
  border-color: rgb(3 105 161 / var(--tw-border-opacity, 1));
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.focus\:ring:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-1:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-2:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-blue-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(147 197 253 / var(--tw-ring-opacity, 1));
}

.focus\:ring-sky-700:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(3 105 161 / var(--tw-ring-opacity, 1));
}

.focus\:ring-slate-400:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(148 163 184 / var(--tw-ring-opacity, 1));
}

.focus\:ring-offset-2:focus {
  --tw-ring-offset-width: 2px;
}

.focus-visible\:ring-2:focus-visible {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus-visible\:ring-white:focus-visible {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(255 255 255 / var(--tw-ring-opacity, 1));
}

.focus-visible\:ring-opacity-75:focus-visible {
  --tw-ring-opacity: .75;
}

.active\:outline-none:active {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.group:hover .group-hover\:flex {
  display: flex;
}

.group:hover .group-hover\:scale-105 {
  --tw-scale-x: 1.05;
  --tw-scale-y: 1.05;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group:hover .group-hover\:opacity-100, .group:hover .group-hover\:hover\:opacity-100:hover, .peer:focus ~ .peer-focus\:opacity-100 {
  opacity: 1;
}

.data-\[closed\]\:-translate-y-1[data-closed] {
  --tw-translate-y: -.25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.data-\[enter\]\:data-\[closed\]\:translate-x-full[data-closed][data-enter], .data-\[leave\]\:data-\[closed\]\:translate-x-full[data-closed][data-leave] {
  --tw-translate-x: 100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.data-\[closed\]\:opacity-0[data-closed] {
  opacity: 0;
}

.data-\[enter\]\:duration-500[data-enter], .data-\[leave\]\:duration-500[data-leave] {
  transition-duration: .5s;
}

@media (min-width: 640px) {
  .sm\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .sm\:ml-auto {
    margin-left: auto;
  }

  .sm\:mr-0 {
    margin-right: 0;
  }

  .sm\:mt-16 {
    margin-top: 4rem;
  }

  .sm\:mt-24 {
    margin-top: 6rem;
  }

  .sm\:flex {
    display: flex;
  }

  .sm\:w-1\/3 {
    width: 33.3333%;
  }

  .sm\:w-auto {
    width: auto;
  }

  .sm\:w-full {
    width: 100%;
  }

  .sm\:max-w-2xl {
    max-width: 42rem;
  }

  .sm\:translate-y-0 {
    --tw-translate-y: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:scale-100 {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:scale-95 {
    --tw-scale-x: .95;
    --tw-scale-y: .95;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .sm\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .sm\:justify-end {
    justify-content: flex-end;
  }

  .sm\:rounded-xl {
    border-radius: .75rem;
  }

  .sm\:p-0 {
    padding: 0;
  }

  .sm\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .sm\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .sm\:py-24 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .sm\:pl-0 {
    padding-left: 0;
  }

  .sm\:pl-6 {
    padding-left: 1.5rem;
  }

  .sm\:pr-0 {
    padding-right: 0;
  }

  .sm\:text-5xl {
    font-size: 3rem;
    line-height: 1;
  }

  .sm\:text-sm {
    font-size: .875rem;
    line-height: 1.25rem;
  }

  .sm\:text-xs {
    font-size: .75rem;
    line-height: 1rem;
  }

  .sm\:tracking-tight {
    letter-spacing: -.025em;
  }

  .sm\:duration-700, .data-\[enter\]\:sm\:duration-700[data-enter], .data-\[leave\]\:sm\:duration-700[data-leave] {
    transition-duration: .7s;
  }
}

@media (min-width: 768px) {
  .md\:absolute {
    position: absolute;
  }

  .md\:relative {
    position: relative;
  }

  .md\:left-4 {
    left: 1rem;
  }

  .md\:right-0 {
    right: 0;
  }

  .md\:right-auto {
    right: auto;
  }

  .md\:top-0 {
    top: 0;
  }

  .md\:top-1\/2 {
    top: 50%;
  }

  .md\:top-auto {
    top: auto;
  }

  .md\:order-1 {
    order: 1;
  }

  .md\:order-2 {
    order: 2;
  }

  .md\:-mr-16 {
    margin-right: -4rem;
  }

  .md\:mb-0 {
    margin-bottom: 0;
  }

  .md\:mt-0 {
    margin-top: 0;
  }

  .md\:mt-2 {
    margin-top: .5rem;
  }

  .md\:block {
    display: block;
  }

  .md\:flex {
    display: flex;
  }

  .md\:grid {
    display: grid;
  }

  .md\:hidden {
    display: none;
  }

  .md\:w-1\/2 {
    width: 50%;
  }

  .md\:w-1\/3 {
    width: 33.3333%;
  }

  .md\:w-2\/12 {
    width: 16.6667%;
  }

  .md\:w-3\/12 {
    width: 25%;
  }

  .md\:w-4\/12 {
    width: 33.3333%;
  }

  .md\:w-60 {
    width: 15rem;
  }

  .md\:w-8\/12 {
    width: 66.6667%;
  }

  .md\:w-\[56px\] {
    width: 56px;
  }

  .md\:w-auto {
    width: auto;
  }

  .md\:flex-1 {
    flex: 1;
  }

  .md\:flex-none {
    flex: none;
  }

  .md\:-translate-y-1\/2 {
    --tw-translate-y: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:rotate-0 {
    --tw-rotate: 0deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:transform-none {
    transform: none;
  }

  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .md\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:flex-col {
    flex-direction: column;
  }

  .md\:flex-nowrap {
    flex-wrap: nowrap;
  }

  .md\:justify-end {
    justify-content: flex-end;
  }

  .md\:justify-between {
    justify-content: space-between;
  }

  .md\:gap-0 {
    gap: 0;
  }

  .md\:gap-1 {
    gap: .25rem;
  }

  .md\:gap-10 {
    gap: 2.5rem;
  }

  .md\:gap-4 {
    gap: 1rem;
  }

  .md\:gap-6 {
    gap: 1.5rem;
  }

  .md\:divide-x > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(1px * var(--tw-divide-x-reverse));
    border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .md\:border-b {
    border-bottom-width: 1px;
  }

  .md\:border-t-0 {
    border-top-width: 0;
  }

  .md\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .md\:pb-0 {
    padding-bottom: 0;
  }

  .md\:pb-1\.5 {
    padding-bottom: .375rem;
  }

  .md\:pb-5 {
    padding-bottom: 1.25rem;
  }

  .md\:pl-3 {
    padding-left: .75rem;
  }

  .md\:pr-0 {
    padding-right: 0;
  }

  .md\:pr-3 {
    padding-right: .75rem;
  }

  .md\:pt-0 {
    padding-top: 0;
  }

  .md\:text-right {
    text-align: right;
  }

  .md\:text-6xl {
    font-size: 3.75rem;
    line-height: 1;
  }

  .md\:opacity-100 {
    opacity: 1;
  }
}

@media (min-width: 1024px) {
  .lg\:pointer-events-none {
    pointer-events: none;
  }

  .lg\:absolute {
    position: absolute;
  }

  .lg\:relative {
    position: relative;
  }

  .lg\:left-0 {
    left: 0;
  }

  .lg\:right-0 {
    right: 0;
  }

  .lg\:right-auto {
    right: auto;
  }

  .lg\:top-auto {
    top: auto;
  }

  .lg\:order-2 {
    order: 2;
  }

  .lg\:col-span-1 {
    grid-column: span 1 / span 1;
  }

  .lg\:m-0 {
    margin: 0;
  }

  .lg\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .lg\:mx-10 {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }

  .lg\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .lg\:ml-0 {
    margin-left: 0;
  }

  .lg\:ml-10 {
    margin-left: 2.5rem;
  }

  .lg\:mt-0 {
    margin-top: 0;
  }

  .lg\:block {
    display: block;
  }

  .lg\:inline-block {
    display: inline-block;
  }

  .lg\:flex {
    display: flex;
  }

  .lg\:grid {
    display: grid;
  }

  .lg\:hidden {
    display: none;
  }

  .lg\:h-full {
    height: 100%;
  }

  .lg\:max-h-\[350px\] {
    max-height: 350px;
  }

  .lg\:w-1\/2 {
    width: 50%;
  }

  .lg\:w-1\/3 {
    width: 33.3333%;
  }

  .lg\:w-16 {
    width: 4rem;
  }

  .lg\:w-\[300px\] {
    width: 300px;
  }

  .lg\:w-auto {
    width: auto;
  }

  .lg\:w-full {
    width: 100%;
  }

  .lg\:max-w-7xl {
    max-width: 80rem;
  }

  .lg\:max-w-none {
    max-width: none;
  }

  .lg\:flex-1 {
    flex: 1;
  }

  .lg\:flex-none {
    flex: none;
  }

  .lg\:flex-shrink-0 {
    flex-shrink: 0;
  }

  .lg\:flex-grow {
    flex-grow: 1;
  }

  .lg\:rotate-0 {
    --tw-rotate: 0deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:transform-none {
    transform: none;
  }

  .lg\:grid-flow-col-dense {
    grid-auto-flow: column dense;
  }

  .lg\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .lg\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .lg\:flex-row {
    flex-direction: row;
  }

  .lg\:flex-col {
    flex-direction: column;
  }

  .lg\:flex-nowrap {
    flex-wrap: nowrap;
  }

  .lg\:items-center {
    align-items: center;
  }

  .lg\:justify-start {
    justify-content: flex-start;
  }

  .lg\:justify-end {
    justify-content: flex-end;
  }

  .lg\:justify-between {
    justify-content: space-between;
  }

  .lg\:gap-0 {
    gap: 0;
  }

  .lg\:gap-12 {
    gap: 3rem;
  }

  .lg\:gap-2\.5 {
    gap: .625rem;
  }

  .lg\:gap-24 {
    gap: 6rem;
  }

  .lg\:gap-6 {
    gap: 1.5rem;
  }

  .lg\:border-b {
    border-bottom-width: 1px;
  }

  .lg\:border-b-0 {
    border-bottom-width: 0;
  }

  .lg\:border-b-2 {
    border-bottom-width: 2px;
  }

  .lg\:border-b-\[0\.5px\] {
    border-bottom-width: .5px;
  }

  .lg\:border-l {
    border-left-width: 1px;
  }

  .lg\:border-l-4 {
    border-left-width: 4px;
  }

  .lg\:border-r-0 {
    border-right-width: 0;
  }

  .lg\:border-r-4 {
    border-right-width: 4px;
  }

  .lg\:border-t-0 {
    border-top-width: 0;
  }

  .lg\:border-t-\[0\.5px\] {
    border-top-width: .5px;
  }

  .lg\:border-b-gray-200 {
    --tw-border-opacity: 1;
    border-bottom-color: rgb(229 231 235 / var(--tw-border-opacity, 1));
  }

  .lg\:border-l-transparent {
    border-left-color: #0000;
  }

  .lg\:border-r-secondary {
    --tw-border-opacity: 1;
    border-right-color: rgb(10 90 133 / var(--tw-border-opacity, 1));
  }

  .lg\:border-r-transparent {
    border-right-color: #0000;
  }

  .lg\:bg-white {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
  }

  .lg\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .lg\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .lg\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .lg\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .lg\:py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .lg\:py-2 {
    padding-top: .5rem;
    padding-bottom: .5rem;
  }

  .lg\:py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .lg\:pb-0 {
    padding-bottom: 0;
  }

  .lg\:pb-1\.5 {
    padding-bottom: .375rem;
  }

  .lg\:pl-3 {
    padding-left: .75rem;
  }

  .lg\:pl-3\.5 {
    padding-left: .875rem;
  }

  .lg\:pr-0 {
    padding-right: 0;
  }

  .lg\:pr-10 {
    padding-right: 2.5rem;
  }

  .lg\:pr-3 {
    padding-right: .75rem;
  }

  .lg\:text-right {
    text-align: right;
  }

  .lg\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .lg\:text-6xl {
    font-size: 3.75rem;
    line-height: 1;
  }

  .lg\:text-7xl {
    font-size: 4.5rem;
    line-height: 1;
  }

  .lg\:text-\[13px\] {
    font-size: 13px;
  }

  .lg\:text-sm {
    font-size: .875rem;
    line-height: 1.25rem;
  }

  .lg\:opacity-0 {
    opacity: 0;
  }

  .lg\:shadow-sm {
    --tw-shadow: 0 1px 2px 0 #0000000d;
    --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .group:hover .lg\:group-hover\:pointer-events-auto {
    pointer-events: auto;
  }

  .group:hover .lg\:group-hover\:opacity-100 {
    opacity: 1;
  }
}

@media (min-width: 1280px) {
  .xl\:order-1 {
    order: 1;
  }

  .xl\:order-3 {
    order: 3;
  }

  .xl\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .xl\:col-span-4 {
    grid-column: span 4 / span 4;
  }

  .xl\:col-span-6 {
    grid-column: span 6 / span 6;
  }

  .xl\:mb-0 {
    margin-bottom: 0;
  }

  .xl\:block {
    display: block;
  }

  .xl\:inline {
    display: inline;
  }

  .xl\:flex {
    display: flex;
  }

  .xl\:grid {
    display: grid;
  }

  .xl\:h-16 {
    height: 4rem;
  }

  .xl\:h-22 {
    height: 5.5rem;
  }

  .xl\:h-40 {
    height: 10rem;
  }

  .xl\:w-1\/3 {
    width: 33.3333%;
  }

  .xl\:w-1\/6 {
    width: 16.6667%;
  }

  .xl\:w-14 {
    width: 3.5rem;
  }

  .xl\:w-2\/12 {
    width: 16.6667%;
  }

  .xl\:w-2\/3 {
    width: 66.6667%;
  }

  .xl\:w-3\/6 {
    width: 50%;
  }

  .xl\:w-40 {
    width: 10rem;
  }

  .xl\:w-5\/12 {
    width: 41.6667%;
  }

  .xl\:min-w-\[352px\] {
    min-width: 352px;
  }

  .xl\:max-w-\[300px\] {
    max-width: 300px;
  }

  .xl\:max-w-\[400px\] {
    max-width: 400px;
  }

  .xl\:max-w-\[900px\] {
    max-width: 900px;
  }

  .xl\:max-w-\[calc\(100\%-300px\)\] {
    max-width: calc(100% - 300px);
  }

  .xl\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .xl\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .xl\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .xl\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  .xl\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }

  .xl\:flex-row {
    flex-direction: row;
  }

  .xl\:flex-nowrap {
    flex-wrap: nowrap;
  }

  .xl\:items-center {
    align-items: center;
  }

  .xl\:justify-end {
    justify-content: flex-end;
  }

  .xl\:gap-5 {
    gap: 1.25rem;
  }

  .xl\:space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1rem * var(--tw-space-x-reverse));
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }

  .xl\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .xl\:pl-8 {
    padding-left: 2rem;
  }

  .xl\:pr-0 {
    padding-right: 0;
  }

  .xl\:pt-0 {
    padding-top: 0;
  }

  .xl\:text-\[10px\] {
    font-size: 10px;
  }
}

@media (min-width: 1536px) {
  .\32 xl\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .\32 xl\:col-span-3 {
    grid-column: span 3 / span 3;
  }

  .\32 xl\:col-span-4 {
    grid-column: span 4 / span 4;
  }

  .\32 xl\:col-span-5 {
    grid-column: span 5 / span 5;
  }

  .\32 xl\:mx-20 {
    margin-left: 5rem;
    margin-right: 5rem;
  }

  .\32 xl\:block {
    display: block;
  }

  .\32 xl\:h-60 {
    height: 15rem;
  }

  .\32 xl\:w-1\/3 {
    width: 33.3333%;
  }

  .\32 xl\:w-2\/3 {
    width: 66.6667%;
  }

  .\32 xl\:w-3\/6 {
    width: 50%;
  }

  .\32 xl\:w-5\/12 {
    width: 41.6667%;
  }

  .\32 xl\:w-7\/12 {
    width: 58.3333%;
  }

  .\32 xl\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .\32 xl\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .\32 xl\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }

  .\32 xl\:flex-row {
    flex-direction: row;
  }

  .\32 xl\:px-20 {
    padding-left: 5rem;
    padding-right: 5rem;
  }

  .\32 xl\:text-\[13px\] {
    font-size: 13px;
  }

  .\32 xl\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
}

@media (min-width: 1125px) and (max-width: 1279px) {
  .lg2\:order-1 {
    order: 1;
  }

  .lg2\:order-2 {
    order: 2;
  }

  .lg2\:col-span-1 {
    grid-column: span 1 / span 1;
  }

  .lg2\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .lg2\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (min-width: 1900px) {
  .\33 xl\:col-span-1 {
    grid-column: span 1 / span 1;
  }

  .\33 xl\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .\33 xl\:max-w-\[60\%\] {
    max-width: 60%;
  }

  .\33 xl\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .\33 xl\:gap-16 {
    gap: 4rem;
  }

  .\33 xl\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (min-width: 1500px) {
  .menuLg\:my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .menuLg\:my-4 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .menuLg\:flex {
    display: flex;
  }

  .menuLg\:hidden {
    display: none;
  }

  .menuLg\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .menuLg\:py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .menuLg\:py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
}

@media (prefers-color-scheme: dark) {
  .dark\:text-gray-600 {
    --tw-text-opacity: 1;
    color: rgb(75 85 99 / var(--tw-text-opacity, 1));
  }
}

.\[\&\:has\(\:focus-visible\)\]\:border:has(:focus-visible) {
  border-width: 1px;
}

.\[\&\:not\(\:first-child\)\]\:border-t:not(:first-child) {
  border-top-width: 1px;
}

.\[\&\:not\(\:first-child\)\]\:pt-4:not(:first-child) {
  padding-top: 1rem;
}

.\[\&\:not\(\:last-child\)\]\:border-b:not(:last-child) {
  border-bottom-width: 1px;
}

.\[\&\:not\(\:last-child\)\]\:border-r:not(:last-child) {
  border-right-width: 1px;
}

.\[\&\:not\(\:last-child\)\]\:border-dotted:not(:last-child) {
  border-style: dotted;
}

.\[\&\:not\(\:last-child\)\]\:pb-2:not(:last-child) {
  padding-bottom: .5rem;
}

.\[\&\:not\(\:last-child\)\]\:pb-4:not(:last-child) {
  padding-bottom: 1rem;
}

.\[\&\:not\(\:last-child\)\]\:pb-5:not(:last-child) {
  padding-bottom: 1.25rem;
}

@media (min-width: 768px) {
  .md\:\[\&\:not\(\:last-child\)\]\:border-b-0:not(:last-child) {
    border-bottom-width: 0;
  }

  .\[\&\:not\(\:last-child\)\]\:md\:pb-0:not(:last-child) {
    padding-bottom: 0;
  }
}
